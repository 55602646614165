import React, { useState, useEffect } from 'react';
import { VStack } from '@chakra-ui/react';
import User from './User';
import firebase from '../../utils/firebase';

const { firestoreDB } = firebase;

const ManageUsers = ({ onClose }) => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('users')
      .onSnapshot((snapshot) =>
        setUsers(snapshot.docs.map((doc) => doc.data())),
      );
    return () => unsub();
  }, []);

  return (
    <VStack align="stretch" flex={1} spacing={6} overflow="scroll">
      <VStack align="stretch" p={4}>
        {users.map((user) => (
          <User key={user.userId} user={user} />
        ))}
      </VStack>
    </VStack>
  );
};

export default ManageUsers;
