import React from 'react';
import { Icon, HStack, Text, Circle } from '@chakra-ui/react';
import { CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons';
import { GiGlassShot } from 'react-icons/gi';

const Bounty = ({ reward, text, claimed, disabled }) => (
  <HStack
    bg="brand.card"
    p={4}
    border="1px solid"
    borderColor="brand.border"
    justify="space-between"
    opacity={disabled && 0.75}
  >
    <HStack spacing={4}>
      {claimed ? (
        <CheckCircleIcon color="brand.green" boxSize={6} />
      ) : (
        <Circle boxSize={6} bg="brand.border">
          {disabled && <NotAllowedIcon boxSize={6} />}
        </Circle>
      )}
      <Text>{text}</Text>
    </HStack>
    <HStack spacing={1}>
      <Text>{reward}</Text>
      <Icon as={GiGlassShot} color="brand.purple" boxSize={6} />
    </HStack>
  </HStack>
);

export default Bounty;
