import React from 'react';
import {
  VStack,
  StackDivider,
  Text,
  HStack,
  Box,
} from '@chakra-ui/react';
import Badge from './Badge';
import Likes from './Likes';
import Chat from './Chat';
import Controls from './Controls';
import { useAuth } from '../../../global/context/AuthProvider';
import { useOption } from '../../../global/context/OptionProvider';
import Prices from './Prices';

const OptionTile = ({ children }) => {
  const { badge, isHalted, isProposal, question } = useOption();
  const { admin } = useAuth();

  return (
    <VStack
      bg="brand.card"
      spacing={0}
      align="stretch"
      justify="center"
      // pos="relative"
      // boxShadow="lg"
    >
      <VStack p={4} spacing={4} align="stretch">
        <HStack justify="space-between">
          <HStack
            divider={
              <StackDivider borderColor="rgba(255,255,255,.16)" />
            }
            border="1px solid rgba(255,255,255,.16)"
            spacing={0}
          >
            <Box px={2}>
              <Badge badge={badge} />
            </Box>
            <Box
              border="1px solid"
              fontWeight="bold"
              borderColor={
                (isProposal && 'brand.purple') ||
                (isHalted && 'brand.red') ||
                'brand.green'
              }
              px={2}
              py={1}
              color={
                (isProposal && 'brand.purple') ||
                (isHalted && 'brand.red') ||
                'brand.green'
              }
              fontSize="xs"
            >
              {(isProposal && 'PROPOSAL') ||
                (isHalted && 'HALTED') ||
                'LIVE'}
            </Box>
          </HStack>
          <Text
            fontSize="md"
            fontWeight="bold"
            color="brand.purple"
            opacity={isHalted && 0.25}
          >
            $1.00
          </Text>
        </HStack>
        <Text opacity={isHalted && 0.25}>{question}</Text>
        {!isProposal && <Prices />}
        <HStack justify="space-between">
          {Chat && <Chat />} {Controls && admin && <Controls />}
          <Likes />
        </HStack>
      </VStack>
      {children}
    </VStack>
  );
};

// <Box
//   w="100%"
//   h="100%"
//   bg="white"
//   pos="absolute"
//   opacity={0.07}
// />

export default OptionTile;
