import React, { useCallback } from 'react';
import {
  Text,
  HStack,
  Box,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useComments } from '../../../global/context/CommentsProvider';
import { useAuth } from '../../../global/context/AuthProvider';
import firebase from '../../../global/utils/firebase';

const { firestoreDB } = firebase;
const Comments = () => {
  const { admin } = useAuth();
  const toast = useToast();
  const handleDelete = useCallback(
    (commentId) =>
      firestoreDB
        .collection('comments')
        .doc(commentId)
        .delete()
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast],
  );
  return useComments().map(({ comment, id }) => (
    <HStack
      key={id}
      border="1px solid rgba(255,255,255,.16)"
      p={4}
      align="center"
      justify="space-between"
    >
      <Box overflowX="hidden" flex={1}>
        <Text fontSize="xs">{comment}</Text>
      </Box>
      {admin && (
        <IconButton
          variant="unstyled"
          icon={<DeleteIcon color="brand.yellow" boxSize={4} />}
          onClick={() => handleDelete(id)}
          size="xs"
        />
      )}
    </HStack>
  ));
};

export default Comments;
