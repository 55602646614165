import React, { useEffect, useState, useCallback } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  InputGroup,
  InputLeftAddon,
  Input,
  Box,
  Text,
} from '@chakra-ui/react';
import firebase from '../utils/firebase';
import ReCaptcha from './ReCaptcha';
import callFunction from '../utils/callFunction';

const { auth } = firebase;

const SignIn = ({
  isOpen,
  onClose,
  phone: initialPhone,
  children,
}) => {
  const [phone, setPhone] = useState();
  const [confirmationResult, setConfirmationResult] = useState();
  const [code, setCode] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (!isOpen) {
      setPhone();
      setConfirmationResult();
      setCode();
      setErrorMessage();
    }
  }, [isOpen]);

  const handleSubmit = useCallback(
    (phoneNumber) =>
      callFunction('confirmNumber', {
        phoneNumber: `+1${phoneNumber}`,
      }).then((res) =>
        res && !res.error
          ? auth
              .signInWithPhoneNumber(
                `+1${phoneNumber}`,
                window.recaptchaVerifier,
              )
              .then(setConfirmationResult)
              .catch((error) => setErrorMessage(error.message))
          : setErrorMessage(
              res.error || 'you are not on the invite list',
            ),
      ),
    [],
  );

  const handleSignIn = useCallback(
    () =>
      confirmationResult.confirm(code).catch((error) => {
        setErrorMessage(error.message);
        setCode();
      }),
    [confirmationResult, code],
  );

  useEffect(() => {
    if (code && code.length === 6) {
      handleSignIn();
    }
  }, [code, handleSignIn]);

  useEffect(() => {
    if (initialPhone) {
      setIsLoading(true);
      handleSubmit(initialPhone);
    }
  }, [initialPhone, handleSubmit]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent rounded={0} bg="brand.background.900">
        <ModalHeader>
          {confirmationResult ? 'Enter code' : 'Sign in'}
        </ModalHeader>
        <ModalCloseButton rounded={0} />
        <ModalBody>
          <VStack>
            {confirmationResult ? (
              <>
                <Text fontSize="xs" color="brand.purple">
                  code sent to {phone || initialPhone}
                </Text>
                <Input
                  otp
                  onChange={(e) => setCode(e.target.value)}
                  disabled={errorMessage}
                />
              </>
            ) : (
              <InputGroup>
                <InputLeftAddon children="+1" rounded={0} />
                <Input
                  type="tel"
                  placeholder="phone number"
                  rounded={0}
                  value={phone || initialPhone}
                  onChange={(e) => setPhone(e.target.value)}
                  isInvalid={phone && phone.length < 10}
                  errorBorderColor="red.300"
                />
              </InputGroup>
            )}
            {errorMessage && (
              <Text fontSize="xs" color="brand.red">
                error: {errorMessage}
              </Text>
            )}
            <Box id="recaptcha-container">
              <ReCaptcha />
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter>
          {!confirmationResult && (
            <Button
              variant="outline"
              border="1px solid"
              borderColor='brand.orange'
              color='brand.orange'
              rounded={0}
              onClick={() => {
                setIsLoading(true);
                handleSubmit(phone);
              }}
              disabled={errorMessage}
              isLoading={isLoading}
            >
              Submit
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignIn;
