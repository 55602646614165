import { useEffect } from 'react';
import firebase from '../utils/firebase';
// import callFunction from '../utils/callFunction';

const { auth, RecaptchaVerifier } = firebase;

const ReCaptcha = () => {
  useEffect(() => {
    auth.useDeviceLanguage();
    window.recaptchaVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
    );
  }, []);
  return null;
};

export default ReCaptcha;
