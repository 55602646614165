import React from 'react';
import Position from './Position';
import { usePositions } from '../../../global/context/PositionsProvider';
import { useHistory } from '../../../global/context/HistoryProvider';
import { OptionProvider } from '../../../global/context/OptionProvider';
import Card from './Card';
import { LikesProvider } from '../../../global/context/LikesProvider';
import { CommentsProvider } from '../../../global/context/CommentsProvider';
import OptionTile from '../components/OptionTile';

const Positions = () =>
  usePositions().map(({ positions, option }) => (
    <OptionProvider option={option} key={option.optionId}>
      <CommentsProvider>
        <LikesProvider>
          <Card idPrefix={`history-${option.optionId}`}>
            <OptionTile option={option}>
              <Position positions={positions} />
            </OptionTile>
          </Card>
        </LikesProvider>
      </CommentsProvider>
    </OptionProvider>
  ));

export default Positions;
