import React, { useCallback } from 'react';
import { HStack, Text, useToast, Circle } from '@chakra-ui/react';
import {
  DeleteIcon,
  CheckCircleIcon,
  NotAllowedIcon,
} from '@chakra-ui/icons';
import firebase from '../../../global/utils/firebase';

const { firestoreDB } = firebase;

const Request = ({
  request: { type, amount, requestId, rejected, cleared },
}) => {
  const toast = useToast();

  const handleCancel = useCallback(
    () =>
      firestoreDB
        .collection('requests')
        .doc(requestId)
        .delete()
        .then(() =>
          toast({
            duration: 2000,
            title: 'Success.',
            description: 'Request canceled',
            status: 'success',
          }),
        )
        // .then(() => setIsOpen(false))
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast, requestId],
  );

  return (
    <HStack
      bg="brand.card"
      p={4}
      border="1px solid"
      borderColor={
        ((cleared || rejected) && 'brand.border') ||
        (type === 'deposit' && 'brand.green') ||
        'brand.red'
      }
      justify="space-between"
    >
      <HStack spacing={4}>
        {cleared ? (
          <CheckCircleIcon color="brand.purple" boxSize={6} />
        ) : (
          <Circle boxSize={6} bg="brand.border">
            {rejected && <NotAllowedIcon boxSize={6} />}
          </Circle>
        )}
        <Text
          color={type === 'deposit' ? 'brand.green' : 'brand.red'}
        >
          {(amount / 100).toFixed(2)}
        </Text>
      </HStack>
      {!cleared && (
        <DeleteIcon
          boxSize={4}
          color="brand.orange"
          onClick={handleCancel}
        />
      )}
    </HStack>
  );
};

export default Request;
