import * as React from 'react';
import { Text } from '@chakra-ui/react';
import { ToggleButtonGroup } from './ToggleButtonGroup';
import { ToggleButton } from './ToggleButton';

const KillAfterToggles = ({
  killAfterInterval,
  setKillAfterInterval,
}) => (
  <ToggleButtonGroup
    value={killAfterInterval}
    onChange={setKillAfterInterval}
    defaultValue="minutes"
    isAttached
    variant="outline"
    flex={1}
  >
    <ToggleButton
      value="minutes"
      rounded={0}
      flex={1}
      icon={
        <Text fontSize="xs" px={4}>
          mins
        </Text>
      }
      w="100%"
    />
    <ToggleButton
      flex={1}
      value="hours"
      rounded={0}
      icon={
        <Text fontSize="xs" px={4}>
          hours
        </Text>
      }
      w="100%"
    />
    <ToggleButton
      flex={1}
      value="days"
      rounded={0}
      w="100%"
      icon={
        <Text fontSize="xs" px={4}>
          days
        </Text>
      }
    />
    <ToggleButton
      flex={1}
      value="none"
      rounded={0}
      w="100%"
      icon={
        <Text fontSize="lg" px={4}>
          ∞
        </Text>
      }
    />
  </ToggleButtonGroup>
);

export default KillAfterToggles;
