import React, { useState, useCallback } from 'react';
import {
  VStack,
  Textarea,
  HStack,
  Text,
  IconButton,
} from '@chakra-ui/react';
import { CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons';
import firebase from '../../../global/utils/firebase';
import { useAuth } from '../../../global/context/AuthProvider';
import { useOption } from '../../../global/context/OptionProvider';

const { firestoreDB, FieldValue } = firebase;

const AddComment = () => {
  const { optionId } = useOption();
  const { userId } = useAuth();

  const [input, setInput] = useState('');

  const handleSubmit = useCallback(
    () =>
      firestoreDB
        .collection('comments')
        .add({
          optionId,
          userId,
          postedOn: FieldValue.serverTimestamp(),
          comment: input,
        })
        .then(() => setInput('')),
    [input, optionId, userId],
  );

  return (
    <VStack align="stretch" spacing={0} py={4}>
      <Textarea
        rounded={0}
        onChange={(e) => setInput(e.target.value)}
        value={input}
        resize="none"
        // size="sm"
        maxLength={140}
      />
      <HStack
        justify="space-between"
        border="1px solid"
        borderColor="brand.border"
        borderTop="0px"
        p={2}
      >
        <IconButton
          variant="unstyled"
          icon={<DeleteIcon color="brand.red" boxSize={4} />}
          onClick={() => setInput('')}
          size="xs"
        />
        <Text fontSize="xs" color="brand.purple">
          {input.length}/140
        </Text>
        <IconButton
          disabled={!input}
          variant="unstyled"
          icon={<CheckCircleIcon color="brand.green" boxSize={4} />}
          onClick={handleSubmit}
          size="xs"
        />
      </HStack>
    </VStack>
  );
};

export default AddComment;
