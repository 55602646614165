import React from 'react';
import {
  VStack,
  StackDivider,
  Text,
  HStack,
  Box,
} from '@chakra-ui/react';
import Badge from './Badge';
import Likes from './Likes';
import Chat from './Chat';

const HistoricalTile = ({
  children,
  option: { question, inTheMoney, badge },
}) => (
  <VStack
    bg="brand.card"
    spacing={0}
    align="stretch"
    justify="center"
  >
    <VStack py={4} spacing={4} align="stretch" px={4}>
      <HStack justify="space-between">
        <HStack
          divider={
            <StackDivider borderColor="rgba(255,255,255,.16)" />
          }
          border="1px solid rgba(255,255,255,.16)"
          spacing={0}
        >
          <Box px={2}>
            <Badge badge={badge} />
          </Box>
          <Box
            border="1px solid"
            fontWeight="bold"
            borderColor={inTheMoney ? 'brand.green' : 'brand.red'}
            px={2}
            py={1}
            color={inTheMoney ? 'brand.green' : 'brand.red'}
            fontSize="xs"
          >
            {inTheMoney ? 'YES' : 'NO'}
          </Box>
        </HStack>
        <Text fontSize="md" fontWeight="bold" color="brand.purple">
          $1.00
        </Text>
      </HStack>
      <Text>{question}</Text>
      <HStack justify="space-between">
        {Chat && <Chat />}
        <Likes />
      </HStack>
    </VStack>
    {children}
  </VStack>
);

// <HStack justify="space-between">
//   {Chat && <Chat />}
//   {Likes && <Likes />}
// </HStack>

export default HistoricalTile;
