import React, { useState, useEffect, useMemo } from 'react';
import firebase from '../utils/firebase';
import { useOptions } from './OptionsProvider';
import { useAuth } from './AuthProvider';

const PositionsContext = React.createContext();

const { firestoreDB } = firebase;

const group = (arr) =>
  arr.reduce(
    (acc, { optionId, ...order }) =>
      acc[optionId]
        ? { ...acc, [optionId]: [...acc[optionId], order] }
        : { ...acc, [optionId]: [order] },
    {},
  );

const PositionsProvider = ({ children }) => {
  const { userId } = useAuth();
  const [positions, setPositions] = useState([]);
  const options = useOptions();
  const [history, setHistory] = useState([]);
  useEffect(() => {
    const unsub = firestoreDB
      .collection('positions')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setPositions(snapshot.docs.map((doc) => doc.data())),
      );

    const historicalUnsub = firestoreDB
      .collection('positionHistory')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setHistory(snapshot.docs.map((doc) => doc.data())),
      );
    return () => unsub() && historicalUnsub();
  }, [userId]);

  const grouped = useMemo(
    () =>
      group([
        ...positions.map((item) => ({ ...item, payout: false })),
        ...history,
      ]),
    [positions, history],
  );

  const positionsWithOptions = useMemo(
    () =>
      Object.entries(grouped)
        .map(([optionId, allPositions]) => ({
          positions: allPositions,
          option: options.find(
            (option) => option.optionId === optionId,
          ),
        }))
        .filter(({ option }) => option),
    [grouped, options],
  );

  return (
    <PositionsContext.Provider value={positionsWithOptions}>
      {children}
    </PositionsContext.Provider>
  );
};

const usePositions = () => {
  const context = React.useContext(PositionsContext);
  if (context === undefined) {
    throw new Error(
      'usePositions must be used within a PositionsProvider',
    );
  }
  return context;
};

export { PositionsProvider, usePositions };
