import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';

const HistoricalOptionsContext = React.createContext();

const { firestoreDB } = firebase;

const HistoricalOptionsProvider = ({ children }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('optionHistory')
      .onSnapshot((snapshot) =>
        setOptions(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            optionId: doc.id,
            isHistorical: true,
          })),
        ),
      );
    return () => unsub();
  }, []);

  return (
    <HistoricalOptionsContext.Provider value={options}>
      {children}
    </HistoricalOptionsContext.Provider>
  );
};

const useHistoricalOptions = () => {
  const context = React.useContext(HistoricalOptionsContext);
  if (context === undefined) {
    throw new Error(
      'useHistoricalOptions must be used within a HistoricalOptionsProvider',
    );
  }
  return context;
};

export { HistoricalOptionsProvider, useHistoricalOptions };
