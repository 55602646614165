import React, { useState, useEffect, useCallback } from 'react';
import {
  VStack,
  StackDivider,
  Text,
  HStack,
  Heading,
  useToast,
  Center,
} from '@chakra-ui/react';
import Request from '../components/Request';
import firebase from '../../../global/utils/firebase';
import { useAuth } from '../../../global/context/AuthProvider';
import Quantity from '../../../components/Quantity';
import ButtonWrapper from '../../../global/wrappers/ButtonWrapper';
import AdminNotifications from './AdminNotifications';

const { firestoreDB, FieldValue } = firebase;

const withdrawOptions = ['25', '50', '100', '250', 'all'];
const depositOptions = ['10', '25', '50', '100', '250'];
const BalanceDrawer = () => {
  const { balance, userId, phoneNumber, admin } = useAuth();
  const [requests, setRequests] = useState([]);
  const [historicalRequests, setHistoricalRequests] = useState([]);
  const [amount, setAmount] = useState('');
  const [type, setType] = useState('deposit');

  const toast = useToast();
  useEffect(() => {
    const unsub = firestoreDB
      .collection('requests')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setRequests(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            requestId: doc.id,
          })),
        ),
      );
    const historicalUnsub = firestoreDB
      .collection('requestHistory')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setHistoricalRequests(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            requestId: doc.id,
            cleared: true,
          })),
        ),
      );
    return () => unsub() && historicalUnsub();
  }, [userId]);

  const handleSubmit = useCallback(
    () =>
      firestoreDB
        .collection('requests')
        .add({
          amount:
            amount === 'all' ? balance : parseInt(amount, 10) * 100,
          type,
          requestedOn: FieldValue.serverTimestamp(),
          userId,
          phoneNumber,
        })
        .then(() =>
          toast({
            duration: 2000,
            title: 'Success.',
            description: 'Request sent',
            status: 'success',
          }),
        )
        .then(() => setAmount(''))
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast, amount, userId, phoneNumber, type, balance],
  );

  useEffect(() => setAmount(), [type]);

  return (
    <VStack
      align="stretch"
      overflow="hidden"
      h="100%"
      divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
      spacing={0}
    >
      <VStack align="stretch" p={4} spacing={4}>
        <HStack justify="center" align="center">
          <Heading
            fontSize="5xl"
            lineHeight="3rem"
            color="brand.green"
          >
            $
          </Heading>
          <Heading fontSize="5xl" lineHeight="3rem">
            {(balance / 100).toFixed(2)}
          </Heading>
        </HStack>
        <HStack align="stretch">
          <Center
            flex={1}
            py={2}
            bg={type === 'withdraw' && 'brand.red'}
            color={type !== 'withdraw' ? 'brand.red' : 'black'}
            onClick={() => setType('withdraw')}
            border="1px solid"
            borderColor="brand.red"
          >
            WITHDRAW
          </Center>
          <Center
            flex={1}
            py={2}
            bg={type === 'deposit' && 'brand.green'}
            color={type !== 'deposit' ? 'brand.green' : 'black'}
            onClick={() => setType('deposit')}
            border="1px solid"
            borderColor="brand.green"
          >
            DEPOSIT
          </Center>
        </HStack>
        {type === 'deposit' && (
          <Quantity
            setQuantity={setAmount}
            hideLabel
            options={depositOptions}
            // defaultValue="50"
            disabled={[]}
            keyPrefix="deposit"
          />
        )}
        {type === 'withdraw' && (
          <Quantity
            setQuantity={setAmount}
            hideLabel
            options={withdrawOptions}
            keyPrefix="withdraw"
            // defaultValue="all"
            disabled={withdrawOptions.filter(
              (val) =>
                val !== 'all' && parseInt(val, 10) * 100 >= balance,
            )}
          />
        )}
        <ButtonWrapper
          variant="outline"
          border="1px solid"
          borderColor="brand.orange"
          color="brand.orange"
          isFullWidth
          rounded={0}
          onClick={handleSubmit}
          disabled={!amount}
        >
          send request
        </ButtonWrapper>
      </VStack>
      {admin && <AdminNotifications />}
      <VStack align="stretch" spacing={4} p={4}>
        <Text color="brand.text">Pending</Text>
        {requests.length ? (
          requests.map((request) => (
            <Request key={request.requestId} request={request} />
          ))
        ) : (
          <Text color="brand.text">
            no requests pending at this time
          </Text>
        )}
      </VStack>
      <VStack align="stretch" spacing={4} p={4}>
        <Text color="brand.text">Past</Text>
        {historicalRequests.map((request) => (
          <Request key={request.requestId} request={request} />
        ))}
      </VStack>
    </VStack>
  );
};

export default BalanceDrawer;
