import React, { useState, useEffect } from 'react';
import { useBoolean } from '@chakra-ui/react';
import firebase from '../utils/firebase';
import Unauthenticated from '../containers/Unauthenticated';
import { OptionsProvider } from './OptionsProvider';
import { ProposalsProvider } from './ProposalsProvider';

import { HistoricalOptionsProvider } from './HistoricalOptionsProvider';

const AuthContext = React.createContext();

const { auth, firestoreDB } = firebase;

const AuthProvider = ({ children }) => {
  const [adminDisabled, setAdminDisabled] = useBoolean();
  const [userId, setUserId] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((signedInUser) => {
      if (signedInUser) {
        setUserId(signedInUser.uid);
      } else {
        setUserId();
        setUser();
      }
    });
    return () => unsub();
  }, []);

  useEffect(() => {
    let unsub;
    if (userId) {
      unsub = firestoreDB
        .collection('users')
        .doc(userId)
        .onSnapshot(
          (doc) => doc.exists && setUser(doc.data()),
          setUserId(userId),
        );
    }
    return () => unsub && unsub();
  }, [userId]);

  if (!user || !userId) {
    return <Unauthenticated />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...user,
        admin: !adminDisabled && user.admin,
        setAdminDisabled,
        isAdmin: user.admin,
      }}
    >
      <OptionsProvider>
        <ProposalsProvider>
          <HistoricalOptionsProvider>
            {children}
          </HistoricalOptionsProvider>
        </ProposalsProvider>
      </OptionsProvider>
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

export { AuthProvider, useAuth };
