import React from 'react';
import { VStack, StackDivider, Box } from '@chakra-ui/react';
import HistoricalTile from '../components/HistoricalTile';
import { useHistory } from '../../../global/context/HistoryProvider';
import { useFilters } from '../../../global/context/FiltersProvider';
import ProfitTable from '../components/ProfitTable';
import Card from './Card';
import { LikesProvider } from '../../../global/context/LikesProvider';
import { CommentsProvider } from '../../../global/context/CommentsProvider';

import { OptionProvider } from '../../../global/context/OptionProvider';

const History = () => {
  const grouped = useHistory(useFilters());
  return (
    <>
      <VStack
        align="stretch"
        divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
        spacing={6}
      >
        <ProfitTable
          positions={grouped.reduce(
            (acc, { positions }) => [...acc, ...positions],
            [],
          )}
        />
        <Box align="stretch">
          {grouped
            .filter(({ currentPositions }) => !currentPositions)
            .map(({ positions, option }) => (
              <OptionProvider option={option} key={option.optionId}>
                <CommentsProvider>
                  <LikesProvider>
                    <Card idPrefix={`history-${option.optionId}`}>
                      <HistoricalTile option={option}>
                        <ProfitTable positions={positions} />
                      </HistoricalTile>
                    </Card>
                  </LikesProvider>
                </CommentsProvider>
              </OptionProvider>
            ))}
        </Box>
      </VStack>
    </>
  );
};

export default History;
