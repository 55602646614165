import React, { useCallback, useState } from 'react';
import {
  HStack,
  Text,
  useToast,
  VStack,
  Input,
  IconButton,
  useBoolean,
  Box,
} from '@chakra-ui/react';
import { CheckCircleIcon, NotAllowedIcon } from '@chakra-ui/icons';
import firebase from '../../../global/utils/firebase';
import callFunction from '../../../global/utils/callFunction';
import ButtonWrapper from '../../../global/wrappers/ButtonWrapper';

const { firestoreDB } = firebase;

const AdminRequest = ({
  request: { type, amount, requestId, phoneNumber },
}) => {
  const [loading, setLoading] = useBoolean();
  const toast = useToast();
  const [input, setInput] = useState('');
  const [amountInput, setAmountInput] = useState('');
  const handleReject = useCallback(
    () =>
      firestoreDB
        .collection('requests')
        .doc(requestId)
        .delete()
        .then(() =>
          toast({
            duration: 2000,
            title: 'Success.',
            description: 'Request canceled',
            status: 'success',
          }),
        )
        // .then(() => setIsOpen(false))
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast, requestId],
  );
  const handleSubmit = useCallback(
    () =>
      callFunction('clearRequest', {
        requestId,
      })
        .then((data) =>
          data.error
            ? toast({
                duration: 2000,
                title: 'An error occurred.',
                description: data.error,
                status: 'error',
              })
            : toast({
                duration: 2000,
                title: 'Success.',
                description: data,
                status: 'success',
              }),
        )
        .then(setLoading.off)
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast, requestId, setLoading],
  );
  return (
    <VStack
      align="stretch"
      bg="brand.card"
      p={4}
      border="1px solid"
      borderColor="brand.border"
      spacing={4}
    >
      <VStack align="center">
        <Text
          color={type === 'deposit' ? 'brand.green' : 'brand.red'}
        >
          {type}
        </Text>

        <HStack spacing={2}>
          <Text>{phoneNumber.slice(2)}</Text>
          <Text>{(amount / 100).toFixed(2)}</Text>
        </HStack>
      </VStack>
      <HStack spacing={1}>
        <IconButton
          variant="unstyled"
          icon={<NotAllowedIcon boxSize={6} color="brand.red" />}
          isLoading={loading}
          w={6}
          minW={6}
          disabled={
            phoneNumber.slice(2) !== input ||
            `${(amount / 100).toFixed(2)}` !== amountInput
          }
          // onClick={() => setLoading.on && handleSubmit()}
        />
        <Box flex={3}>
          <Input
            placeholder={`${phoneNumber.slice(2)}`}
            onChange={(e) => setInput(e.target.value)}
            rounded={0}
          />
        </Box>
        <Box flex={2}>
          <Input
            placeholder={`${(amount / 100).toFixed(2)}`}
            onChange={(e) => setAmountInput(e.target.value)}
            rounded={0}
          />
        </Box>
        <IconButton
          variant="unstyled"
          icon={<CheckCircleIcon color="brand.green" boxSize={6} />}
          isLoading={loading}
          w={6}
          minW={6}
          disabled={
            phoneNumber.slice(2) !== input ||
            `${(amount / 100).toFixed(2)}` !== amountInput
          }
          onClick={() => setLoading.on && handleSubmit()}
        />
      </HStack>
    </VStack>
  );
};

export default AdminRequest;
