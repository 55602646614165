import firebase from './firebase';

const { functions } = firebase;

const callFunction = (cloudFunction, data) =>
  functions
    .httpsCallable(cloudFunction)(data)
    .then((res) => res.data);

export default callFunction;
