import React from 'react';
import { HStack, Heading, Box } from '@chakra-ui/react';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { BalanceProvider } from '../../../global/context/BalanceProvider';
import Balance from '../components/Balance';

const Header = ({ header }) => (
  <Box bg="brand.background">
    <HStack
      justify="space-between"
      align="center"
      px={4}
      pt={4}
      pb={2}
    >
      <HStack spacing={2}>
        <Heading size="md" fontWeight="thin" lineHeight={6}>
          {header}
        </Heading>
        {header !== 'notifications' && (
          <QuestionOutlineIcon color="brand.orange" boxSize={6} />
        )}
      </HStack>
      <BalanceProvider>
        <Balance />
      </BalanceProvider>
    </HStack>
  </Box>
);

export default Header;
