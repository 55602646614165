import React, { useCallback } from 'react';
import { VStack, Text, HStack } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

const Notification = ({ message, type, docRef }) => {
  const handleHide = useCallback(
    () => docRef.set({ hidden: true }, { merge: true }),
    [docRef],
  );

  return (
    <VStack
      align="stretch"
      border="1px solid rgba(255,255,255,.16)"
      p={2}
      spacing={2}
    >
      <HStack justify="space-between">
        <Text fontSize="xs" color="brand.text">
          {type}
        </Text>
        <CloseIcon
          boxSize={3}
          onClick={handleHide}
          color="brand.orange"
        />
      </HStack>
      <Text fontSize="sm">{message}</Text>
    </VStack>
  );
};
export default Notification;
