import React, { useCallback, useState } from 'react';
import { HStack, Text, useToast, Spinner } from '@chakra-ui/react';
import {
  DeleteIcon,
  CloseIcon,
  CheckCircleIcon,
} from '@chakra-ui/icons';
import callFunction from '../../../global/utils/callFunction';
import { useOption } from '../../../global/context/OptionProvider';

const OrderSlip = ({ side = 'buy', price = 20, quantity = 1 }) => {
  const { optionId } = useOption();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toast = useToast();

  const handleCancel = useCallback(
    () =>
      callFunction('cancelOrder', { side, quantity, optionId })
        .then((data) =>
          data.error
            ? toast({
                duration: 2000,
                title: 'An error occurred.',
                description: data.error,
                status: 'error',
              })
            : toast({
                duration: 2000,
                title: 'Success.',
                description: data,
                status: 'success',
              }),
        )
        // .then(() => setIsModalOpen(false))
        .catch(
          (err) =>
            toast({
              duration: 2000,
              title: 'An error occurred.',
              description: 'Something went wrong.',
              status: 'error',
            }) && setIsModalOpen(false),
        ),
    [side, toast, quantity, optionId],
  );

  return (
    <HStack
      align="center"
      justify="space-between"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="brand.border"
      p={4}
    >
      <HStack align="flex-end" fontSize="2xl">
        <Text
          fontWeight="bold"
          color={side === 'buy' ? 'brand.green' : 'brand.red'}
        >
          {((quantity * price) / 100).toFixed(2) || '-'}
        </Text>
        <Text fontWeight="light">for</Text>
        <Text
          fontWeight="bold"
          color={side === 'sell' ? 'brand.green' : 'brand.red'}
        >
          {((quantity * 100 - quantity * price) / 100).toFixed(2) ||
            '-'}
        </Text>
      </HStack>
      {(isModalOpen && isLoading && <Spinner />) ||
        (isModalOpen && (
          <CheckCircleIcon
            color="brand.orange"
            onClick={(e) => {
              e.stopPropagation();
              setIsLoading(true);
              handleCancel();
            }}
          />
        ))}
      {isModalOpen ? (
        <CloseIcon
          color={isLoading && 'brand.border'}
          onClick={(e) => {
            e.stopPropagation();
            return !isLoading && setIsModalOpen(false);
          }}
        />
      ) : (
        <DeleteIcon
          color="brand.orange"
          onClick={(e) => {
            e.stopPropagation();

            return setIsModalOpen(true);
          }}
        />
      )}
    </HStack>
  );
};

export default OrderSlip;
