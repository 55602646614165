import React, { useEffect } from 'react';
import {
  HStack,
  Heading,
  StackDivider,
  Icon,
  Box,
  useBoolean,
} from '@chakra-ui/react';
import { LockIcon, UnlockIcon } from '@chakra-ui/icons';
import { GiGlassShot } from 'react-icons/gi';
import { useBalance } from '../../../global/context/BalanceProvider';
import { useAuth } from '../../../global/context/AuthProvider';

const Balance = () => {
  const [lock, setLock] = useBoolean(true);
  const { shots, setAdminDisabled, isAdmin } = useAuth();
  const { total, available, pending } = useBalance();

  return (
    <HStack>
      <HStack
        align="center"
        outline="1px solid"
        outlineColor="brand.border"
        px={2}
        divider={<StackDivider borderColor="brand.border" />}
        py={1}
      >
        <Heading fontSize="xs" lineHeight={4} color="brand.green">
          $
        </Heading>
        <Heading
          fontSize="xs"
          // align="left"
          lineHeight={4}
          color="brand.text"
        >
          {(available / 100).toFixed(2)}
        </Heading>
      </HStack>
      <HStack
        align="center"
        outline="1px solid"
        outlineColor="brand.border"
        px={2}
        divider={<StackDivider borderColor="brand.border" />}
        py={1}
      >
        <Icon as={GiGlassShot} color="brand.purple" boxSize={3} />
        <Heading
          fontSize="xs"
          // align="left"
          lineHeight={4}
          color="brand.text"
        >
          {shots}
        </Heading>
      </HStack>
      {isAdmin && (
        <Box
          outline="1px solid"
          outlineColor={lock ? 'brand.yellow' : 'brand.border'}
          px={2}
          onClick={() => {
            setLock.toggle();
            return lock
              ? setAdminDisabled.on()
              : setAdminDisabled.off();
          }}
        >
          {lock ? (
            <LockIcon color="brand.yellow" boxSize={3} />
          ) : (
            <UnlockIcon color="brand.border" boxSize={3} />
          )}
        </Box>
      )}
    </HStack>
  );
};

export default Balance;
