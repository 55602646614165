import React, { useState, useEffect } from 'react';
import {
  VStack,
  Box,
  StackDivider,
  Text,
  HStack,
} from '@chakra-ui/react';
import firebase from '../../utils/firebase';
import Invited from './Invited';
import Invite from './Invite';
import { useAuth } from '../../context/AuthProvider';

const { firestoreDB } = firebase;

const InviteUser = () => {
  const { userId, invites } = useAuth();
  const [invited, setInvited] = useState([]);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('invites')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setInvited(snapshot.docs.map((doc) => doc.data())),
      );
    return () => unsub();
  }, [userId]);

  return (
    <VStack
      align="stretch"
      overflow="hidden"
      h="100%"
      divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
      spacing={0}
    >
      <VStack align="stretch" py={4}>
        <HStack justify="center" align="center" pb={4}>
          <VStack align="center" justify="center" spacing={0}>
            <Text fontSize="3xl" fontWeight="semibold">
              {invites}
            </Text>
          </VStack>
        </HStack>
        <Text fontSize="xl">New Invite</Text>
        <Invite isDisabled={!invites || invites === 0} />
      </VStack>
      <VStack align="stretch" flex={1} py={4} overflow="hidden">
        <Text fontSize="xl">Open Invites</Text>
        <Box overflow="scroll">
          <VStack
            align="stretch"
            divider={
              <StackDivider borderColor="rgba(255,255,255,.16)" />
            }
            spacing={0}
          >
            {invited.map((invite) => (
              <Invited key={invite.phoneNumber} invite={invite} />
            ))}
          </VStack>
        </Box>
      </VStack>
    </VStack>
  );
};

export default InviteUser;
