import React from 'react';
import { HStack, Icon } from '@chakra-ui/react';
import {
  GiBasketballBall,
  GiAmericanFootballHelmet,
  GiSoccerBall,
  GiGolfFlag,
  GiBaseballGlove,
  GiCardRandom,
  GiClapperboard,
  GiPodiumWinner,
} from 'react-icons/gi';

const mapping = {
  basketball: GiBasketballBall,
  football: GiAmericanFootballHelmet,
  soccer: GiSoccerBall,
  golf: GiGolfFlag,
  baseball: GiBaseballGlove,
  olympics: GiPodiumWinner,
  // test: OlympicsIcon,
  movies: GiClapperboard,
  miscellaneous: GiCardRandom,
  // moonshot: GiSpaceShuttle,
};

const ClickableBadges = ({
  badges = [],
  setBadges,
  setBadge,
  badge,
}) => (
  <HStack justify="space-between">
    {Object.entries(mapping).map(([key, value]) => (
      <Icon
        key={key}
        as={value}
        boxSize={6}
        stroke={badge === key ? 'brand.purple' : 'white'}
        color={badge === key && 'brand.purple'}
        onClick={() =>
          setBadge
            ? setBadge(key)
            : setBadges((prev) =>
                prev.find((item) => item === key)
                  ? prev.filter((item) => item !== key)
                  : [...prev, key],
              )
        }
      />
    ))}
  </HStack>
);

export default ClickableBadges;
