import React, { useState, useEffect } from 'react';
import { useDisclosure, HStack, Text } from '@chakra-ui/react';
import { ChatIcon } from '@chakra-ui/icons';
import firebase from '../../../global/utils/firebase';
import { useOption } from '../../../global/context/OptionProvider';

const { firestoreDB } = firebase;

const Chat = () => {
  const { optionId } = useOption();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('comments')
      .where('optionId', '==', optionId)
      .orderBy('postedOn', 'desc')
      .onSnapshot((snapshot) =>
        setComments(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        ),
      );
    return () => unsub();
  }, [optionId]);

  return (
    <HStack spacing={1} flex={1} justify="flex-start">
      <ChatIcon color="brand.orange" />
      <Text color="brand.purple">x {comments.length}</Text>
    </HStack>
  );
};

export default Chat;
