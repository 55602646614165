import React, { useCallback, useState, useRef, useMemo } from 'react';
import {
  HStack,
  VStack,
  StackDivider,
  Text,
  useOutsideClick,
  useToast,
  useClipboard,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import ButtonWrapper from '../../wrappers/ButtonWrapper';
import callFunction from '../../utils/callFunction';

const Invited = ({ invite: { phoneNumber } }) => {
  const ref = useRef();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideClick({
    ref,
    handler: () => setIsOpen(false),
  });
  const phone = useMemo(() => phoneNumber.slice(2), [phoneNumber]);
  const shareLink = useMemo(
    () => `https://speakeasy.fans?phone=${phone}`,
    [phone],
  );
  const { hasCopied, onCopy } = useClipboard(shareLink);

  const toast = useToast();

  const handleCancel = useCallback(
    () =>
      callFunction('cancelInvite', { phoneNumber })
        .then((data) =>
          data.error
            ? toast({duration: 2000,
                title: 'An error occurred.',
                description: data.error,
                status: 'error',
              })
            : toast({duration: 2000,
                title: 'Success.',
                description: data,
                status: 'success',
              }),
        )
        .then(() => setIsOpen(false))
        .catch((err) =>
          toast({duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast, phoneNumber],
  );

  return (
    <VStack
      border="1px solid rgba(255,255,255,.16)"
      align="stretch"
      flex={1}
      spacing={0}
      divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
    >
      <HStack
        align="stretch"
        justify="center"
        flex={1}
        divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
      >
        <VStack spacing={1} flex={1} p={2} align="center">
          <Text lineHeight="1.5rem">{phone || '-'}</Text>
        </VStack>
        <HStack
          flex={isOpen ? 4 : 0.25}
          transition="all .2s"
          align="stretch"
          justify="center"
        >
          {isOpen ? (
            <VStack
              align="center"
              justify="center"
              flex={1}
              ref={ref}
            >
              <ButtonWrapper
                align="center"
                justify="center"
                variant="unstyled"
                onClick={handleCancel}
                isFullWidth
                rounded={0}
                color='brand.orange'
                fontSize="xs"
              >
                tap to confirm
              </ButtonWrapper>
            </VStack>
          ) : (
            <VStack
              onClick={setIsOpen}
              align="center"
              justify="center"
              flex={1}
            >
              <DeleteIcon color='brand.orange' />
            </VStack>
          )}
        </HStack>
      </HStack>
      <VStack
        align="center"
        flex={1}
        onClick={onCopy}
        p={2}
        spacing={0}
      >
        <Text fontSize="xs">{shareLink}</Text>
        <Text color='brand.orange' fontSize="xs">
          {hasCopied ? 'copied' : 'tap to copy'}
        </Text>
      </VStack>
    </VStack>
  );
};

export default Invited;
