import React from 'react';
import {
  TabPanels,
  TabPanel,
  Box,
  Tabs,
  TabList,
  Tab,
} from '@chakra-ui/react';
import Options from './Options';
import Orders from './Orders';
import Positions from './Positions';
import Proposals from './Proposals';
import SubmitProposal from '../components/SubmitProposal';
import Filters from '../components/Filters';
import { PositionsProvider } from '../../../global/context/PositionsProvider';
import { OrdersProvider } from '../../../global/context/OrdersProvider';
import { HistoryProvider } from '../../../global/context/HistoryProvider';
import History from './History';
import { FiltersProvider } from '../../../global/context/FiltersProvider';
import ScrollWrapper from '../wrappers/ScrollWrapper';

const topNavStyle = {
  borderColor: 'white',
  outline: null,
  color: 'white',
};

const Speakeasy = () => (
  <Tabs isFitted size="md" w="100%">
    <ScrollWrapper showProps={{ top: 12 }} hideProps={{ top: 0 }}>
      <TabList color="brand.text" bg="brand.background">
        <Tab _selected={topNavStyle} _focus={{ boxShadow: null }}>
          Options
        </Tab>
        <Tab _selected={topNavStyle} _focus={{ boxShadow: null }}>
          Orders
        </Tab>
        <Tab _selected={topNavStyle} _focus={{ boxShadow: null }}>
          Positions
        </Tab>
        <Tab _selected={topNavStyle} _focus={{ boxShadow: null }}>
          History
        </Tab>
      </TabList>
    </ScrollWrapper>
    <FiltersProvider>
      <HistoryProvider>
        <TabPanels>
          <TabPanel px={4}>
            <Filters />
            <Box align="stretch" pt={4}>
              <Options />
              <Proposals />
              <SubmitProposal />
            </Box>
          </TabPanel>
          <TabPanel px={4}>
            <OrdersProvider>
              <Box align="stretch">
                <Orders />
              </Box>
            </OrdersProvider>
          </TabPanel>
          <TabPanel px={4}>
            <PositionsProvider>
              <Box align="stretch">
                <Positions />
              </Box>
            </PositionsProvider>
          </TabPanel>
          <TabPanel px={4}>
            <Filters />
            <Box align="stretch" pt={4}>
              <History />
            </Box>
          </TabPanel>
        </TabPanels>
      </HistoryProvider>
    </FiltersProvider>
  </Tabs>
);

export default Speakeasy;
