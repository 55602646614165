import React from 'react';
import { VStack, Text, HStack } from '@chakra-ui/react';
import { useOption } from '../../../global/context/OptionProvider';

const Prices = () => {
  const { buy, sell, isHalted, setSide, side } = useOption();
  return (
    <HStack align="stretch" justify="center" flex={1}>
      <VStack
        spacing={1}
        flex={1}
        p={2}
        align="center"
        border="1px solid"
        borderColor="brand.red"
        opacity={isHalted && 0.25}
        onClick={(e) => {
          e.stopPropagation();
          setSide('sell');
        }}
        bg={side === 'sell' && 'brand.red'}
      >
        <Text
          fontSize="sm"
          flex={1}
          color={side === 'sell' && 'black'}
        >
          NO
        </Text>
        <Text
          fontSize="2xl"
          color={
            (side === 'buy' && 'brand.red') ||
            (side === 'sell' && 'black')
          }
          fontWeight="bold"
          lineHeight="1.5rem"
        >
          {100 - sell ? `.${100 - sell}` : '-'}
        </Text>
      </VStack>
      <VStack
        spacing={1}
        flex={1}
        p={2}
        align="center"
        border="1px solid"
        borderColor="brand.green"
        opacity={isHalted && 0.25}
        onClick={(e) => {
          e.stopPropagation();
          setSide('buy');
        }}
        bg={side === 'buy' && 'brand.green'}
      >
        <Text
          fontSize="sm"
          flex={1}
          color={side === 'buy' && 'black'}
        >
          YES
        </Text>
        <Text
          fontSize="2xl"
          color={
            (side === 'sell' && 'brand.green') ||
            (side === 'buy' && 'black')
          }
          fontWeight="bold"
          lineHeight="1.5rem"
        >
          {buy ? `.${buy}` : '-'}
        </Text>
      </VStack>
    </HStack>
  );
};

export default Prices;
