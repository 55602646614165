import { HStack, useRadioGroup } from '@chakra-ui/react';
import * as React from 'react';
import RadioOption from './RadioOption';

const RadioGroup = (props) => {
  const {
    name,
    options,
    onChange,
    defaultValue,
    disabled,
    keyPrefix,
    ...rest
  } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    onChange,
    defaultValue,
  });
  return (
    <HStack
      spacing={{
        base: 2,
        md: 4,
      }}
      {...getRootProps(rest)}
    >
      {options.map((value) => (
        <RadioOption
          key={`${keyPrefix}_${value}`}
          {...getRadioProps({
            value,
            isDisabled: disabled?.find((val) => val === value),
          })}
          // isDisabled={!!disabled?.find((val) => val === value)}
        >
          {value}
        </RadioOption>
      ))}
    </HStack>
  );
};
export default RadioGroup;
