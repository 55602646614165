import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDSdnM9b2yXHr5mlp_0T_9nrvx3eV5NiZc',
  authDomain: 'cardstock-f7ab1.firebaseapp.com',
  databaseURL: 'https://cardstock-f7ab1.firebaseio.com',
  projectId: 'cardstock-f7ab1',
  storageBucket: 'cardstock-f7ab1.appspot.com',
  messagingSenderId: '853664563424',
  appId: '1:853664563424:web:b2d0299c53730f75481085',
  measurementId: 'G-WSEDT00HRQ',
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const functions = firebase.functions();
const firestoreDB = firebase.firestore();
const analytics = firebase.analytics();
const auth = firebase.auth();

if (window.location.host === 'localhost:5000') {
  functions.useEmulator('localhost', 5001);
  firestoreDB.useEmulator('localhost', 8080);
  auth.useEmulator('http://localhost:9099/');
}

export default {
  firebaseApp,
  firestoreDB,
  auth,
  functions,
  analytics,
  FieldValue: firebase.firestore.FieldValue,
  Timestamp: firebase.firestore.Timestamp,
  provider: new firebase.auth.GoogleAuthProvider(),
  RecaptchaVerifier: firebase.auth.RecaptchaVerifier,
};
