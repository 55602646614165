import React, { useMemo } from 'react';
import { HStack, VStack, Text, StackDivider } from '@chakra-ui/react';

const ProfitTable = ({ positions }) => {
  const cost = useMemo(
    () =>
      positions.reduce(
        (acc, { side, price }) =>
          side === 'buy' ? acc + price : acc + (100 - price),
        0,
      ),
    [positions],
  );
  const payout = useMemo(
    () =>
      positions.reduce(
        (acc, { payout: posPayout }) =>
          (posPayout && acc + 100) ||
          (posPayout !== false && acc + 50) ||
          acc,
        0,
      ),
    [positions],
  );
  const fees = useMemo(
    () =>
      positions.reduce(
        (acc, { payout: posPayout }) =>
          (posPayout && acc + 2) || acc + 1,
        0,
      ),
    [positions],
  );

  const profit = useMemo(
    () => payout - cost - fees,
    [payout, cost, fees],
  );

  const exits = useMemo(
    () =>
      positions.filter(
        (item) => item.payout !== true && item.payout !== false,
      ).length / 2,
    [positions],
  );

  const yes = useMemo(
    () => positions.filter(({ side }) => side === 'buy').length,
    [positions],
  );

  const no = useMemo(
    () => positions.filter(({ side }) => side === 'sell').length,
    [positions],
  );

  return (
    <HStack
      divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
      spacing={0}
      bg="brand.card"
      border="1px solid rgba(255,255,255,.16)"
    >
      <VStack align="center" flex={1} spacing={0}>
        <Text fontSize="sm">PROFIT</Text>
        <Text
          fontSize="xl"
          fontWeight="semibold"
          color={
            (profit > 0 && 'brand.green') ||
            (profit < 0 && 'brand.red')
          }
        >
          {profit}
        </Text>
      </VStack>
      <VStack
        flex={2}
        align="stretch"
        spacing={0}
        divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
      >
        <HStack
          divider={
            <StackDivider borderColor="rgba(255,255,255,.16)" />
          }
          spacing={0}
        >
          <VStack
            align="center"
            flex={1}
            spacing={0}
            divider={
              <StackDivider borderColor="rgba(255,255,255,.16)" />
            }
          >
            <Text fontSize="xs">EXITS</Text>
            <Text fontWeight="semibold" py={2}>
              {exits}
            </Text>
          </VStack>
          <VStack
            align="center"
            flex={1}
            spacing={0}
            divider={
              <StackDivider borderColor="rgba(255,255,255,.16)" />
            }
          >
            <Text fontSize="xs">NO</Text>
            <Text fontWeight="semibold" py={2}>
              {no}
            </Text>
          </VStack>
          <VStack
            align="center"
            flex={1}
            spacing={0}
            divider={
              <StackDivider borderColor="rgba(255,255,255,.16)" />
            }
          >
            <Text fontSize="xs">YES</Text>
            <Text fontWeight="semibold" py={2}>
              {yes}
            </Text>
          </VStack>
        </HStack>
        <HStack
          flex={2}
          divider={
            <StackDivider borderColor="rgba(255,255,255,.16)" />
          }
          spacing={0}
        >
          <VStack
            align="center"
            flex={1}
            spacing={0}
            divider={
              <StackDivider borderColor="rgba(255,255,255,.16)" />
            }
          >
            <Text fontSize="xs">WON</Text>
            <Text fontWeight="semibold" py={2}>
              {payout}
            </Text>
          </VStack>
          <VStack
            align="center"
            flex={1}
            spacing={0}
            divider={
              <StackDivider borderColor="rgba(255,255,255,.16)" />
            }
          >
            <Text fontSize="xs">PAID</Text>
            <Text fontWeight="semibold" py={2}>
              {cost}
            </Text>
          </VStack>
          <VStack
            align="center"
            flex={1}
            spacing={0}
            divider={
              <StackDivider borderColor="rgba(255,255,255,.16)" />
            }
          >
            <Text fontSize="xs">FEES</Text>
            <Text fontWeight="semibold" py={2}>
              {fees}
            </Text>
          </VStack>
        </HStack>
      </VStack>
    </HStack>
  );
};
export default ProfitTable;
