import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';
import { useAuth } from './AuthProvider';

const BalanceContext = React.createContext();

const { firestoreDB } = firebase;

const BalanceProvider = ({ children }) => {
  const { userId, balance } = useAuth();
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPositions, setTotalPositions] = useState(0);
  const [pending, setPending] = useState(0);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('orders')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setTotalOrders(
          snapshot.docs.reduce(
            (acc, doc) =>
              doc.data().side === 'buy'
                ? doc.data().price + acc
                : 100 - doc.data().price + acc,
            0,
          ),
        ),
      );
    const positionUnsub = firestoreDB
      .collection('positions')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setTotalPositions(
          snapshot.docs.reduce(
            (acc, doc) =>
              doc.data().side === 'buy'
                ? doc.data().price + acc
                : 100 - doc.data().price + acc,
            0,
          ),
        ),
      );

    const requestUnsub = firestoreDB
      .collection('requests')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setPending(
          snapshot.docs.reduce(
            (acc, doc) =>
              doc.data().type === 'deposit'
                ? doc.data().amount + acc
                : acc,
            0,
          ),
        ),
      );
    return () => unsub() && positionUnsub() && requestUnsub();
  }, [userId]);

  return (
    <BalanceContext.Provider
      value={{
        total: balance + totalOrders + totalPositions,
        available: balance,
        pending,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

const useBalance = () => {
  const context = React.useContext(BalanceContext);
  if (context === undefined) {
    throw new Error(
      'useBalance must be used within a BalanceProvider',
    );
  }
  return context;
};

export { BalanceProvider, useBalance };
