import React from 'react';
import {
  VStack,
  useDisclosure,
  HStack,
  Button,
} from '@chakra-ui/react';
import Invites from '../../../global/drawers/InviteUser';
import firebase from '../../../global/utils/firebase';
import ButtonWrapper from '../../../global/wrappers/ButtonWrapper';
import { useAuth } from '../../../global/context/AuthProvider';
import DrawerWrapper from '../../../global/wrappers/DrawerWrapper';
import ManageUsers from '../../../global/drawers/ManageUsers';

const { auth } = firebase;

const handleSignOut = () => {
  auth
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};
const Account = () => {
  const { admin } = useAuth();
  const drawer = useDisclosure();
  return (
    <>
      <VStack align="stretch" px={4} spacing={4}>
        <HStack>
          <ButtonWrapper
            onClick={handleSignOut}
            rounded={0}
            variant="outline"
            border="1px solid"
            borderColor="brand.orange"
            color="brand.orange"
            isFullWidth
          >
            sign out
          </ButtonWrapper>
          {admin && (
            <Button
              onClick={drawer.onOpen}
              rounded={0}
              variant="outline"
              border="1px solid"
              borderColor="brand.yellow"
              color="brand.yellow"
              isFullWidth
            >
              manage users
            </Button>
          )}
        </HStack>
        <Invites />
      </VStack>
      <DrawerWrapper
        onClose={drawer.onClose}
        isOpen={drawer.isOpen}
        header="Manage Users"
      >
        <ManageUsers onClose={drawer.onClose} />
      </DrawerWrapper>
    </>
  );
};

export default Account;
