import React, { useEffect, useState } from 'react';
import { VStack } from '@chakra-ui/react';
import firebase from '../../../global/utils/firebase';
import Notification from '../components/Notification';
import { useAuth } from '../../../global/context/AuthProvider';

const { firestoreDB } = firebase;

const Notifications = () => {
  const { userId } = useAuth();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('users')
      .doc(userId)
      .collection('notifications')
      .where('hidden', '==', false)
      .onSnapshot((snapshot) =>
        setNotifications(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            notificationId: doc.id,
            docRef: doc.ref,
          })),
        ),
      );
    return () => unsub();
  }, [userId]);

  return (
    <VStack align="stretch" spacing={2} px={4}>
      {notifications.map(
        ({ message, notificationId, type, docRef }) => (
          <Notification
            key={notificationId}
            message={message}
            type={type}
            docRef={docRef}
          />
        ),
      )}
    </VStack>
  );
};

export default Notifications;
