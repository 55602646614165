import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useLayoutEffect,
} from 'react';
import {
  VStack,
  useToast,
  HStack,
  Switch,
  Text,
  Box,
  useDisclosure,
  NumberInput,
  NumberInputField,
} from '@chakra-ui/react';
import PriceSlider from '../components/PriceSlider';
import Summary from '../components/Summary';
import ButtonWrapper from '../../../global/wrappers/ButtonWrapper';
import callFunction from '../../../global/utils/callFunction';
import { useOption } from '../../../global/context/OptionProvider';
import KillAfterToggles from '../../../components/KillAfterToggles';
import Quantity from '../../../components/Quantity';

const OrderForm = ({ quantity: initialQuantity = 1 }) => {
  const {
    buy,
    sell,
    isProposal,
    isHistorical,
    side,
    setSide,
    ...option
  } = useOption();

  const { isOpen, onToggle, onClose } = useDisclosure();
  const [price, setPrice] = useState(
    (side === 'buy' && buy) || (side === 'sell' && 100 - sell) || 50,
  );
  const [quantity, setQuantity] = useState('1');
  const [killAfter, setKillAfter] = useState(60);
  const [killAfterInterval, setKillAfterInterval] =
    useState('minutes');

  const toast = useToast();

  useLayoutEffect(() => {
    if (side === 'buy' && buy) {
      setPrice(buy);
    } else if (side === 'sell' && sell) {
      setPrice(100 - sell);
    } else {
      setPrice((prev) => 100 - prev);
    }
  }, [side, buy, sell]);

  const killAfterSeconds = useMemo(
    () =>
      (killAfterInterval === 'minutes' &&
        parseInt(killAfter, 10) * 60) ||
      (killAfterInterval === 'hours' &&
        parseInt(killAfter, 10) * 60 * 60) ||
      (killAfterInterval === 'days' &&
        parseInt(killAfter, 10) * 60 * 60 * 24) ||
      0,
    [killAfter, killAfterInterval],
  );

  const handleOrderPlacement = useCallback(
    () =>
      callFunction('placeOrder', {
        optionId: option.optionId,
        quantity: parseInt(quantity, 10),
        side,
        killAfter: killAfterSeconds,
        price:
          side === 'buy'
            ? parseInt(price, 10)
            : 100 - parseInt(price, 10),
      })
        .then((data) =>
          data.error
            ? toast({
                duration: 2000,
                title: 'An error occurred.',
                description: data.error,
                status: 'error',
              })
            : toast({
                duration: 2000,
                title: 'Success.',
                description: data,
                status: 'success',
              }),
        )
        // .then(onClose)
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [price, side, quantity, option.optionId, toast, killAfterSeconds],
  );
  if (isProposal || isHistorical) return null;
  return (
    <Box
      bg="brand.card"
      borderBottom="1px solid rgba(255,255,255,.16)"
      py={4}
    >
      <VStack align="stretch" spacing={4}>
        <HStack align="center" justify="flex-start">
          <Switch
            onChange={onToggle}
            sx={{
              '.chakra-switch__track': {
                _checked: { bg: 'brand.purple' },
              },
            }}
            isChecked={isOpen}
            // size="lg"
          />
          <Text fontSize="xs" flex={1} align="left">
            advanced options
          </Text>
        </HStack>
        <PriceSlider
          // price={parseInt(price, 10)}
          price={price}
          setPrice={setPrice}
          side={side}
        />
        {isOpen && (
          <>
            <Quantity setQuantity={setQuantity} />
            <VStack align="stretch" spacing={1}>
              <Text fontSize="xs">kill after</Text>
              <HStack spacing={2}>
                <NumberInput
                  step={1}
                  flex={1}
                  defaultValue={60}
                  min={1}
                  onChange={setKillAfter}
                  rounded={0}
                >
                  <NumberInputField rounded={0} />
                </NumberInput>
                <KillAfterToggles
                  killAfterInterval={killAfterInterval}
                  setKillAfterInterval={setKillAfterInterval}
                />
              </HStack>
            </VStack>
          </>
        )}
        <Summary
          quantity={quantity}
          price={parseInt(price, 10)}
          side={side}
        />
        <ButtonWrapper
          variant="outline"
          border="1px solid"
          borderColor="brand.orange"
          color="brand.orange"
          isFullWidth
          rounded={0}
          onClick={handleOrderPlacement}
        >
          place order
        </ButtonWrapper>
      </VStack>
    </Box>
  );
};

// <HStack justify="space-between" spacing={0} fontSize="xs">
//   <Text align="left" flex={1} onClick={() => setPrice(1)}>
//     99 : 1
//   </Text>
//   <Text
//     flex={1}
//     fontSize="xs"
//     align="left"
//     onClick={() => setPrice(25)}
//   >
//     3 : 1
//   </Text>
//   <Text
//     flex={1}
//     align="center"
//     onClick={() => setPrice(50)}
//   >
//     ± 100
//   </Text>
//   <Text
//     flex={1}
//     align="right"
//     onClick={() => setPrice(75)}
//   >
//     1 : 3
//   </Text>
//   <Text
//     align="right"
//     flex={1}
//     onClick={() => setPrice(99)}
//   >
//     1 : 99
//   </Text>
// </HStack>

export default OrderForm;
