import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';

const OptionContext = React.createContext();

const { firestoreDB } = firebase;

const OptionProvider = ({ children, option }) => {
  const [sell, setSell] = useState();
  const [buy, setBuy] = useState();
  const [side, setSide] = useState();

  useEffect(() => {
    const sellUnsub = firestoreDB
      .collection('orders')
      .where('optionId', '==', option.optionId)
      .where('side', '==', 'sell')
      .orderBy('price')
      .limit(1)
      .onSnapshot((snapshot) =>
        !snapshot.empty
          ? setBuy(snapshot.docs[0].data().price)
          : setBuy(),
      );

    const buyUnsub = firestoreDB
      .collection('orders')
      .where('optionId', '==', option.optionId)
      .where('side', '==', 'buy')
      .orderBy('price', 'desc')
      .limit(1)
      .onSnapshot((snapshot) =>
        !snapshot.empty
          ? setSell(snapshot.docs[0].data().price)
          : setSell(),
      );

    return () => sellUnsub() && buyUnsub();
  }, [option.optionId]);

  return (
    <OptionContext.Provider
      value={{ ...option, buy, sell, side, setSide }}
    >
      {children}
    </OptionContext.Provider>
  );
};

const useOption = () => {
  const context = React.useContext(OptionContext);
  if (context === undefined) {
    throw new Error('useOption must be used within a OptionProvider');
  }
  return context;
};

export { OptionProvider, useOption };
