import React, { useState, useEffect, useMemo } from 'react';
import firebase from '../utils/firebase';
import { useHistoricalOptions } from './HistoricalOptionsProvider';
import { useOptions } from './OptionsProvider';
import { useAuth } from './AuthProvider';

const HistoryContext = React.createContext();

const { firestoreDB } = firebase;

const group = (arr) =>
  arr.reduce(
    (acc, { optionId, ...order }) =>
      acc[optionId]
        ? { ...acc, [optionId]: [...acc[optionId], order] }
        : { ...acc, [optionId]: [order] },
    {},
  );

const HistoryProvider = ({ children, OptionTile }) => {
  const { userId } = useAuth();
  const [history, setHistory] = useState([]);
  const historicalOptions = useHistoricalOptions();
  const options = useOptions();

  useEffect(() => {
    // maybe nest historical positions in user doc
    const unsub = firestoreDB
      .collection('positionHistory')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setHistory(snapshot.docs.map((doc) => doc.data())),
      );
    return () => unsub();
  }, [userId]);

  const grouped = useMemo(() => group(history), [history]);

  const withOptions = useMemo(
    () =>
      Object.entries(grouped)
        .map(([optionId, positions]) => ({
          positions,
          option: historicalOptions.find(
            (option) => option.optionId === optionId,
          ),
          currentPositions: options.find(
            (option) => option.optionId === optionId,
          ),
        }))
        .filter(
          ({ option, currentPositions }) =>
            option || currentPositions,
        ),
    [historicalOptions, grouped, options],
  );

  return (
    <HistoryContext.Provider value={withOptions}>
      {children}
    </HistoryContext.Provider>
  );
};

const useHistory = (filters = []) => {
  const context = React.useContext(HistoryContext);
  if (context === undefined) {
    throw new Error(
      'useHistory must be used within a HistoryProvider',
    );
  }
  return context.flatMap((history) =>
    !filters.length ||
    filters.find((key) => history?.option?.badge === key)
      ? [history]
      : [],
  );
};

export { HistoryProvider, useHistory };
