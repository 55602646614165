import React from 'react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';

const PriceSlider = ({ side, price, setPrice }) => (
  <Slider
    max={99}
    min={1}
    defaultValue={price}
    value={price}
    isReversed={side === 'buy'}
    onChange={(newPrice) => setPrice(newPrice)}
    rounded={0}
    h={6}
  >
    <SliderTrack
      h={6}
      rounded={0}
      bg={side === 'buy' ? 'brand.red' : 'brand.green'}
    >
      <SliderFilledTrack
        bg={side === 'sell' ? 'brand.red' : 'brand.green'}
      />
    </SliderTrack>
    <SliderThumb
      // fontSize="md"
      // fontWeight="semibold"
      boxSize={8}
      // rounded={0}
      shadow="xl"
      bg="brand.form"
      color={side === 'buy' ? 'brand.green' : 'brand.red'}
      fontWeight={800}
    >
      {price}
    </SliderThumb>
  </Slider>
);

export default PriceSlider;
