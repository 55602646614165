import React, { useMemo } from 'react';
import { HStack, VStack, Text, StackDivider } from '@chakra-ui/react';

const ProfitTable = ({ positions }) => {
  const cost = useMemo(
    () =>
      positions.reduce(
        (acc, { side, price }) =>
          side === 'buy' ? acc + price : acc + (100 - price),
        0,
      ),
    [positions],
  );
  const payout = useMemo(
    () =>
      positions.reduce(
        (acc, { payout: posPayout }) =>
          (posPayout && acc + 100) ||
          (posPayout !== false && acc + 50) ||
          acc,
        0,
      ),
    [positions],
  );

  const exits = useMemo(
    () =>
      positions.filter(
        (item) => item.payout !== true && item.payout !== false,
      ).length / 2,
    [positions],
  );

  const yes = useMemo(
    () => positions.filter(({ side }) => side === 'buy').length,
    [positions],
  );

  const no = useMemo(
    () => positions.filter(({ side }) => side === 'sell').length,
    [positions],
  );

  const yesCost = useMemo(
    () =>
      positions.reduce(
        (acc, { side, price }) =>
          side === 'buy' ? acc + price : acc,
        0,
      ),
    [positions],
  );

  const noCost = useMemo(
    () =>
      positions.reduce(
        (acc, { side, price }) =>
          side === 'buy' ? acc : acc + (100 - price),
        0,
      ),
    [positions],
  );

  return (
    <HStack
      spacing={0}
      justify="space-between"
      align="stretch"
      bg="brand.card"
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="brand.border"
    >
      <VStack flex={1} p={2} pl={4} align="stretch">
        <HStack flex={1} fontSize="xl">
          <Text fontWeight={200} color="brand.text">
            risk
          </Text>
          <Text fontWeight="semibold">{cost - exits * 100}</Text>
        </HStack>
        <HStack flex={1} fontSize="xl">
          <Text fontWeight={200} color="brand.text">
            reward
          </Text>
          <Text fontWeight="semibold">
            {(positions.length - exits) * 100 - cost}
          </Text>
        </HStack>
      </VStack>
      <VStack
        align="flex-end"
        spacing={0}
        divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
      >
        <HStack fontSize="2xl" flex={1} pr={4}>
          <Text fontWeight="bold" color="brand.red">
            {yesCost || '-'}
          </Text>
          <Text fontWeight="light">for</Text>
          <Text fontWeight="bold" color="brand.green">
            {yes * 100 || '-'}
          </Text>
        </HStack>
        <HStack fontSize="2xl" flex={1} pr={4}>
          <Text fontWeight="bold" color="brand.green">
            {noCost || '-'}
          </Text>
          <Text fontWeight="light">for</Text>
          <Text fontWeight="bold" color="brand.red">
            {no * 100 || '-'}
          </Text>
        </HStack>
      </VStack>
    </HStack>
  );
};
export default ProfitTable;
