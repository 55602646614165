import { Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import RadioGroup from './RadioGroup';

const Quantity = ({
  setQuantity,
  hideLabel,
  options = ['1', '5', '10', '25', '50', '100'],
  defaultValue = '1',
  disabled = [],
  keyPrefix = '',
}) => (
  <VStack align="stretch" spacing={1}>
    {!hideLabel && <Text fontSize="xs">quantity</Text>}
    <RadioGroup
      name="quantity"
      options={options}
      defaultValue={defaultValue}
      onChange={setQuantity}
      disabled={disabled}
      keyPrefix={keyPrefix}
    />
  </VStack>
);

export default Quantity;
