import React from 'react';
import { useOrders } from '../../../global/context/OrdersProvider';
import { OptionProvider } from '../../../global/context/OptionProvider';
import OrderSlip from '../components/OrderSlip';
import Card from './Card';
import { LikesProvider } from '../../../global/context/LikesProvider';
import { CommentsProvider } from '../../../global/context/CommentsProvider';
import OptionTile from '../components/OptionTile';
import Summary from '../components/Summary';

const Orders = () =>
  useOrders().map(({ buyOrders, sellOrders, option }) => (
    <OptionProvider option={option} key={option.optionId}>
      <CommentsProvider>
        <LikesProvider>
          <Card idPrefix={`order-${option.optionId}`}>
            <OptionTile>
              {sellOrders.length > 0 && (
                <OrderSlip
                  side="sell"
                  price={
                    sellOrders.reduce(
                      (acc, val) => acc + (100 - val.price),
                      0,
                    ) / sellOrders.length
                  }
                  quantity={sellOrders.length}
                />
              )}
              {buyOrders.length > 0 && (
                <OrderSlip
                  side="buy"
                  price={
                    buyOrders.reduce(
                      (acc, val) => acc + val.price,
                      0,
                    ) / buyOrders.length
                  }
                  quantity={buyOrders.length}
                />
              )}
            </OptionTile>
          </Card>
        </LikesProvider>
      </CommentsProvider>
    </OptionProvider>
  ));

export default Orders;
