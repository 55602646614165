import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';
import { useOption } from './OptionProvider';

const CommentsContext = React.createContext();

const { firestoreDB } = firebase;

const CommentsProvider = ({ children }) => {
  const { optionId } = useOption();
  const [comments, setComments] = useState([]);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('comments')
      .where('optionId', '==', optionId)
      .orderBy('postedOn', 'desc')
      .onSnapshot((snapshot) =>
        setComments(
          snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })),
        ),
      );
    return () => unsub();
  }, [optionId]);

  return (
    <CommentsContext.Provider value={comments}>
      {children}
    </CommentsContext.Provider>
  );
};

const useComments = () => {
  const context = React.useContext(CommentsContext);
  if (context === undefined) {
    throw new Error(
      'useComments must be used within a CommentsProvider',
    );
  }
  return context;
};

export { CommentsProvider, useComments };
