import React from 'react';
import { motion, isValidMotionProp } from 'framer-motion';
import { Box, VStack, forwardRef } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import OrderForm from './OrderForm';
import Comments from '../components/Comments';
import AddComment from '../components/AddComment';
import { useOption } from '../../../global/context/OptionProvider';
// 1. Create a custom motion component from Box
const MotionBox = motion.custom(
  forwardRef((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(
        ([key]) => !isValidMotionProp(key),
      ),
    );
    return <Box ref={ref} {...chakraProps} />;
  }),
);
const Card = ({ children, idPrefix }) => {
  const { setSide, side } = useOption();

  return (
    <>
      {side && (
        <MotionBox
          layoutId={`${idPrefix}_container`}
          pos="fixed"
          // style={{ top: scrollY }}
          top={0}
          left={0}
          h="100vh"
          maxH="100%"
          bg="brand.card"
          px={4}
          overflowY="scroll"
          pb={24}
          w="100%"
          zIndex={1}
        >
          <MotionBox py={2} px={4} pos="relative">
            <CloseIcon
              onClick={() => setSide()}
              pos="absolute"
              top={4}
              right={4}
            />
          </MotionBox>
          <MotionBox
            layoutId={`${idPrefix}_card`}
            borderColor="transparent"
          >
            {children}
          </MotionBox>
          <MotionBox px={4}>
            <OrderForm />
            <VStack align="stretch" py={4}>
              <AddComment />
              <Comments />
            </VStack>
          </MotionBox>
        </MotionBox>
      )}
      <MotionBox layoutId={`${idPrefix}_container`} pb={4}>
        <MotionBox
          onClick={() => setSide('buy')}
          layoutId={`${idPrefix}_card`}
          border="1px solid rgba(255,255,255,.16)"
        >
          {children}
        </MotionBox>
      </MotionBox>
    </>
  );
};

export default Card;
