import React, { useState, useEffect, useCallback } from 'react';
import firebase from '../utils/firebase';
import { useOption } from './OptionProvider';
import { useAuth } from './AuthProvider';

const LikesContext = React.createContext();

const { firestoreDB } = firebase;

const LikesProvider = ({ children }) => {
  const { userId } = useAuth();
  const { optionId } = useOption();
  const [hasHearted, setHasHearted] = useState(false);
  const [voteCount, setVoteCount] = useState(0);

  useEffect(() => {
    const unsubOne = firestoreDB
      .collection('votes')
      .doc(`${optionId}_${userId}`)
      .onSnapshot((snapshot) => setHasHearted(snapshot.exists));
    const unsubTwo = firestoreDB
      .collection('votes')
      .where('optionId', '==', optionId)
      .onSnapshot((snapshot) => setVoteCount(snapshot.size));
    return () => unsubOne() && unsubTwo();
  }, [optionId, userId]);

  const handleSubmit = useCallback(
    () =>
      hasHearted
        ? firestoreDB
            .collection('votes')
            .doc(`${optionId}_${userId}`)
            .delete()
        : firestoreDB
            .collection('votes')
            .doc(`${optionId}_${userId}`)
            .set({
              userId,
              optionId,
            }),
    [optionId, userId, hasHearted],
  );

  return (
    <LikesContext.Provider
      value={{ hasHearted, voteCount, handleSubmit }}
    >
      {children}
    </LikesContext.Provider>
  );
};

const useLikes = () => {
  const context = React.useContext(LikesContext);
  if (context === undefined) {
    throw new Error('useLikes must be used within a LikesProvider');
  }
  return context;
};

export { LikesProvider, useLikes };
