import React, { useState } from 'react';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Icon,
  Center,
  Box,
} from '@chakra-ui/react';
import { FaExchangeAlt } from 'react-icons/fa';
import { GiGlassShot, GiPiggyBank } from 'react-icons/gi';
import { BellIcon } from '@chakra-ui/icons';
import { AnimateSharedLayout } from 'framer-motion';
import Speakeasy from './Speakeasy';
import Funds from './Funds';
import Account from './Account';
import Notifications from './Notifications';
import Bounties from './Bounties';
import Header from './Header';
import ScrollWrapper from '../wrappers/ScrollWrapper';

const style = {
  border: null,
  outline: null,
  color: 'brand.orange',
};
const pageNames = [
  'speakeasy',
  'funds',
  'account',
  'bounties',
  'notifications',
];
const BottomNav = () => {
  return (
    <Center w="100%">
      <AnimateSharedLayout>
        <Tabs
          variant="unstyled"
          isFitted
          size="lg"
          w="100%"
          // lazyBehavior="keepMounted"
        >
          <TabPanels pb={16}>
            <TabPanel px={0} pt="5.5rem">
              <ScrollWrapper
                showProps={{ top: 0 }}
                hideProps={{ top: -12 }}
              >
                <Header header="speakeasy" />
              </ScrollWrapper>
              <Speakeasy />
            </TabPanel>
            <TabPanel px={0} pt={12}>
              <ScrollWrapper
                showProps={{ top: 0 }}
                hideProps={{ top: -12 }}
              >
                <Header header="funds" />
              </ScrollWrapper>
              <Funds />
            </TabPanel>
            <TabPanel px={0} pt={12}>
              <ScrollWrapper
                showProps={{ top: 0 }}
                hideProps={{ top: -12 }}
              >
                <Header header="account" />
              </ScrollWrapper>
              <Account />
            </TabPanel>
            <TabPanel px={0} pt={12}>
              <ScrollWrapper
                showProps={{ top: 0 }}
                hideProps={{ top: -12 }}
              >
                <Header header="bounties" />
              </ScrollWrapper>
              <Bounties />
            </TabPanel>
            <TabPanel px={0} pt={12}>
              <ScrollWrapper
                showProps={{ top: 0 }}
                hideProps={{ top: -12 }}
              >
                <Header header="notifications" />
              </ScrollWrapper>
              <Notifications />
            </TabPanel>
          </TabPanels>
          <ScrollWrapper
            showProps={{ bottom: 0 }}
            hideProps={{ bottom: -24 }}
            zIndex={0}
          >
            <TabList
              bg="brand.foreground"
              borderTop="2px solid"
              borderColor="brand.border"
              boxShadow="xl"
              w="100%"
              pb={3}
            >
              <Tab
                _selected={style}
                _focus={{ boxShadow: null }}
                py={5}
              >
                <Icon as={FaExchangeAlt} boxSize={6} />
              </Tab>
              <Tab
                _selected={style}
                _focus={{ boxShadow: null }}
                py={5}
              >
                <Icon
                  as={GiPiggyBank}
                  boxSize={6}
                  // color="brand.orange"
                />
              </Tab>
              <Tab
                _selected={style}
                _focus={{ boxShadow: null }}
                fontSize="4xl"
                lineHeight={6}
              >
                🤫
              </Tab>
              <Tab
                _selected={style}
                _focus={{ boxShadow: null }}
                py={5}
              >
                <Icon
                  as={GiGlassShot}
                  boxSize={6}
                  // color="brand.orange"
                />
              </Tab>
              <Tab
                stroke="white"
                _selected={{
                  border: null,
                  outline: null,
                  stroke: 'brand.orange',
                }}
                _focus={{ boxShadow: null }}
                py={5}
              >
                <BellIcon boxSize={6} color="transparent" />
              </Tab>
            </TabList>
          </ScrollWrapper>
        </Tabs>
      </AnimateSharedLayout>
    </Center>
  );
};

export default BottomNav;
