import React from 'react';
import AdminControls from './AdminControls';
import { useOption } from '../../../global/context/OptionProvider';
import AdminProposalControls from './AdminProposalControls';

const Controls = () => {
  const { isProposal } = useOption();
  if (!isProposal) return <AdminControls />;
  return <AdminProposalControls />;
};

export default Controls;
