import React, { useState, useCallback, useEffect } from 'react';
import {
  VStack,
  Collapse,
  Textarea,
  HStack,
  useToast,
  Badge as ChakraBadge,
} from '@chakra-ui/react';
import { CheckCircleIcon, DeleteIcon } from '@chakra-ui/icons';
import firebase from '../../../global/utils/firebase';
import ClickableBadges from '../../../global/drawers/Propose/ClickableBadges';
import Badge from './Badge';
import { useAuth } from '../../../global/context/AuthProvider';

const { firestoreDB, FieldValue } = firebase;

const ProposalForm = ({ isOpen, onClose }) => {
  const { userId } = useAuth();
  const [badge, setBadge] = useState('miscellaneous');
  const [question, setQuestion] = useState('Will');

  const toast = useToast();

  useEffect(() => {
    if (!isOpen) {
      setQuestion('Will');
      setBadge('miscellaneous');
    }
  }, [isOpen]);

  const handleSubmit = useCallback(
    () =>
      firestoreDB
        .collection('proposals')
        .add({
          userId,
          question,
          proposedOn: FieldValue.serverTimestamp(),
          badge,
        })
        .then((data) =>
          data.error
            ? toast({
                duration: 2000,
                title: 'An error occurred.',
                description: data.error,
                status: 'error',
              })
            : toast({
                duration: 2000,
                title: 'Success.',
                description: 'Proposal submitted',
                status: 'success',
              }),
        )
        .then(onClose)
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [question, onClose, toast, userId, badge],
  );

  return (
    <Collapse in={isOpen} unmountOnExit>
      <VStack align="stretch">
        <HStack h={8} justify="center" align="center">
          {badge && <Badge badge={badge} />}
          <ChakraBadge
            // color="brand.purple"
            bg="rgba(182, 56, 255,.6)"
          >
            PROPOSAL
          </ChakraBadge>
          {badge && <Badge badge={badge} />}
        </HStack>
        <ClickableBadges setBadge={setBadge} badge={badge} />
        <Textarea
          rounded={0}
          onChange={(e) => setQuestion(e.target.value)}
          size="lg"
          value={question}
          resize="none"
        />
        <HStack justify="space-between">
          <DeleteIcon color="brand.red" onClick={onClose} />
          <CheckCircleIcon
            color="brand.green"
            onClick={handleSubmit}
          />
        </HStack>
      </VStack>
    </Collapse>
  );
};

export default ProposalForm;
