import { useId, useRadio, Center } from '@chakra-ui/react';
import * as React from 'react';

const RadioOption = (props) => {
  const { getInputProps, getCheckboxProps, getLabelProps } =
    useRadio(props);
  const id = useId();
  const { isDisabled, isChecked, ...checkboxProps } =
    getCheckboxProps(props);
  return (
    <label {...getLabelProps()} style={{ flex: 1 }}>
      <input {...getInputProps()} aria-labelledby={id} />
      <Center
        id={id}
        size={{
          base: '10',
          md: '12',
        }}
        h={10}
        borderWidth="1px"
        transition="all 0.2s"
        cursor="pointer"
        border="1px solid"
        borderColor="brand.border"
        // _hover={{
        //   bg: mode('gray.100', 'whiteAlpha.200'),
        // }}
        // _active={{
        //   bg: mode('gray.200', 'whiteAlpha.300'),
        // }}
        _checked={{
          fontWeight: 'bold',
          color: 'brand.purple',
          borderColor: 'brand.purple',
        }}
        // _focus={{
        //   shadow: 'outline',
        // }}
        _disabled={{ opacity: 0.5 }}
        {...checkboxProps}
      />
    </label>
  );
};

export default RadioOption;
