import React, { useState, useCallback } from 'react';
import {
  VStack,
  useToast,
  Box,
  StackDivider,
  HStack,
  Input,
  Text,
} from '@chakra-ui/react';
import ButtonWrapper from '../../../global/wrappers/ButtonWrapper';
import callFunction from '../../../global/utils/callFunction';
import { useOption } from '../../../global/context/OptionProvider';

const CloseOption = ({ onClose }) => {
  const { question, optionId } = useOption();
  const [input, setInput] = useState();
  const toast = useToast();
  const [side, setSide] = useState();

  const handleSubmit = useCallback(
    () =>
      callFunction('closeOption', {
        optionId,
        side,
      })
        .then((data) =>
          data.error
            ? toast({
                duration: 2000,
                title: 'An error occurred.',
                description: data.error,
                status: 'error',
              })
            : toast({
                duration: 2000,
                title: 'Success.',
                description: data,
                status: 'success',
              }),
        )
        .then(() => setSide())
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast, side, optionId],
  );

  return (
    <Box bg="brand.card" border="1px solid rgba(255,255,255,.16)">
      <VStack
        divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
        spacing={0}
        align="stretch"
        justify="center"
      >
        <Text align="center" fontSize="lg" flex={1} p={4}>
          {question}
        </Text>
        <HStack
          align="stretch"
          justify="center"
          flex={1}
          spacing={0}
          divider={
            <StackDivider borderColor="rgba(255,255,255,.16)" />
          }
        >
          <VStack
            spacing={1}
            flex={1}
            p={2}
            align="center"
            onClick={() => setSide('sell')}
            borderBottom={
              side === 'sell'
                ? '2px solid white'
                : '2px solid transparent'
            }
          >
            <Text
              fontSize="2xl"
              color="brand.red"
              fontWeight="bold"
              lineHeight="1.5rem"
            >
              NO
            </Text>
            <Text
              fontSize="xs"
              color={
                side === 'sell' ? 'brand.purple' : 'brand.orange'
              }
            >
              {(side === 'sell' && 'selected') || 'tap to select'}
            </Text>
          </VStack>
          <VStack
            spacing={1}
            flex={1}
            p={2}
            align="center"
            onClick={() => setSide('buy')}
            borderBottom={
              side === 'buy'
                ? '2px solid white'
                : '2px solid transparent'
            }
          >
            <Text
              fontSize="2xl"
              color="brand.green"
              fontWeight="bold"
              lineHeight="1.5rem"
            >
              YES
            </Text>
            <Text
              fontSize="xs"
              color={side === 'buy' ? 'brand.purple' : 'brand.orange'}
            >
              {(side === 'buy' && 'selected') || 'tap to select'}
            </Text>
          </VStack>
        </HStack>
        <HStack flex={1} spacing={0}>
          <Input
            placeholder="type 'close' to close"
            onChange={(e) => setInput(e.target.value)}
            rounded={0}
          />
          <ButtonWrapper
            variant="outline"
            border="1px solid"
            borderColor="brand.orange"
            color="brand.orange"
            rounded={0}
            onClick={handleSubmit}
            disabled={!side || input !== 'close'}
          >
            close
          </ButtonWrapper>
        </HStack>
      </VStack>
    </Box>
  );
};

export default CloseOption;
