import React, { useState, useEffect, useMemo } from 'react';
import firebase from '../utils/firebase';
import { useOptions } from './OptionsProvider';
import { useAuth } from './AuthProvider';

const OrdersContext = React.createContext();

const { firestoreDB } = firebase;

const group = (orders) =>
  orders.reduce(
    (acc, { optionId, ...order }) =>
      acc[optionId]
        ? { ...acc, [optionId]: [...acc[optionId], order] }
        : { ...acc, [optionId]: [order] },
    {},
  );

const OrdersProvider = ({ children }) => {
  const { userId } = useAuth();
  const [orders, setOrders] = useState([]);
  const options = useOptions();

  useEffect(() => {
    const unsub = firestoreDB
      .collection('orders')
      .where('userId', '==', userId)
      .onSnapshot((snapshot) =>
        setOrders(snapshot.docs.map((doc) => doc.data())),
      );
    return () => unsub();
  }, [userId]);

  const groupedOrders = useMemo(() => group(orders), [orders]);

  const ordersWithOptions = useMemo(
    () =>
      Object.entries(groupedOrders)
        .map(([optionId, allOrders]) => ({
          buyOrders: allOrders.filter(({ side }) => side === 'buy'),
          sellOrders: allOrders.filter(({ side }) => side === 'sell'),
          option: options.find(
            (option) => option.optionId === optionId,
          ),
        }))
        .filter(({ option }) => option),
    [groupedOrders, options],
  );

  return (
    <OrdersContext.Provider value={ordersWithOptions}>
      {children}
    </OrdersContext.Provider>
  );
};

const useOrders = () => {
  const context = React.useContext(OrdersContext);
  if (context === undefined) {
    throw new Error('useOrders must be used within a OrdersProvider');
  }
  return context;
};

export { OrdersProvider, useOrders };
