import React from 'react';
import { VStack, Text, useDisclosure } from '@chakra-ui/react';
import ProposalForm from './ProposalForm';

const SubmitProposal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <VStack
      align="stretch"
      bg="brand.card"
      py={2}
      px={4}
      spacing={0}
      border="1px solid rgba(255,255,255,.16)"
    >
      <ProposalForm isOpen={isOpen} onClose={onClose} />
      <Text
        fontSize="xs"
        color="brand.orange"
        align="center"
        onClick={isOpen ? onClose : onOpen}
      >
        {!isOpen && 'tap to propose option'}
      </Text>
    </VStack>
  );
};

export default SubmitProposal;
