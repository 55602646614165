import React, { useState, useLayoutEffect } from 'react';
import { Box, forwardRef } from '@chakra-ui/react';
import {
  motion,
  isValidMotionProp,
  useViewportScroll,
} from 'framer-motion';

const MotionBox = motion.custom(
  forwardRef((props, ref) => {
    const chakraProps = Object.fromEntries(
      // do not pass framer props to DOM element
      Object.entries(props).filter(
        ([key]) => !isValidMotionProp(key),
      ),
    );
    return <Box ref={ref} {...chakraProps} />;
  }),
);

const ScrollWrapper = ({
  children,
  showProps,
  hideProps,
  zIndex = 1,
}) => {
  const [show, setShow] = useState(true);
  const { scrollYProgress } = useViewportScroll();

  useLayoutEffect(
    () =>
      scrollYProgress.onChange(
        (latest) =>
          (latest < 0.95 &&
            latest < scrollYProgress?.prev &&
            setShow(true)) ||
          (latest > 0.025 &&
            latest > scrollYProgress?.prev &&
            setShow(false)),
      ),
    [scrollYProgress],
  );

  return (
    <MotionBox
      layout
      pos="fixed"
      {...(show ? showProps : hideProps)}
      zIndex={zIndex}
      w="100%"
      transition={{ duration: 0.2 }}
    >
      {children}
    </MotionBox>
  );
};

export default ScrollWrapper;
