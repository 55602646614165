import React from 'react';
import { HStack, Icon } from '@chakra-ui/react';
import {
  GiBasketballBall,
  GiAmericanFootballHelmet,
  GiSoccerBall,
  GiGolfFlag,
  GiBaseballGlove,
  GiCardRandom,
  GiClapperboard,
  GiPodiumWinner,
} from 'react-icons/gi';

import {
  useFilters,
  useFiltersDispatch,
} from '../../../global/context/FiltersProvider';

const mapping = {
  basketball: GiBasketballBall,
  football: GiAmericanFootballHelmet,
  soccer: GiSoccerBall,
  golf: GiGolfFlag,
  baseball: GiBaseballGlove,
  olympics: GiPodiumWinner,
  // test: OlympicsIcon,
  movies: GiClapperboard,
  miscellaneous: GiCardRandom,
  // moonshot: GiSpaceShuttle,
};

const Filters = () => {
  const filters = useFilters();
  const setFilters = useFiltersDispatch();
  return (
    <HStack justify="space-between" pos="sticky" top={0}>
      {Object.entries(mapping).map(([key, value]) => (
        <Icon
          key={key}
          as={value}
          boxSize={6}
          stroke={
            filters.find((item) => item === key)
              ? 'brand.purple'
              : 'white'
          }
          color={
            filters.find((item) => item === key) && 'brand.purple'
          }
          onClick={() =>
            setFilters((prev) =>
              prev.find((item) => item === key)
                ? prev.filter((item) => item !== key)
                : [...prev, key],
            )
          }
        />
      ))}
    </HStack>
  );
};

export default Filters;
