import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';

const ProposalsContext = React.createContext();

const { firestoreDB } = firebase;

const ProposalsProvider = ({ children }) => {
  const [proposals, setProposals] = useState([]);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('proposals')
      .onSnapshot((snapshot) =>
        setProposals(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            optionId: doc.id,
            isProposal: true,
          })),
        ),
      );
    return () => unsub();
  }, []);

  return (
    <ProposalsContext.Provider value={proposals}>
      {children}
    </ProposalsContext.Provider>
  );
};

const useProposals = (filters = []) => {
  const context = React.useContext(ProposalsContext);
  if (context === undefined) {
    throw new Error(
      'useProposals must be used within a ProposalsProvider',
    );
  }

  return context.flatMap((option) =>
    !filters.length || filters.find((key) => option.badge === key)
      ? [option]
      : [],
  );
};

export { ProposalsProvider, useProposals };
