import React, { useState, useCallback } from 'react';
import { Button } from '@chakra-ui/react';

const ButtonWrapper = ({ children, onClick, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(() => {
    setIsLoading(true);
    return onClick().then(() => setIsLoading(false));
  }, [onClick]);

  return (
    <Button
      {...props}
      onClick={handleClick}
      isLoading={isLoading}
      fontSize={isLoading ? 'lg' : props.fontSize}
      // disabled={props.disabled || !account}
    >
      {children}
    </Button>
  );
};
export default ButtonWrapper;
