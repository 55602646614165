import React, { useState, useCallback } from 'react';
import {
  Box,
  VStack,
  useToast,
  HStack,
  Textarea,
  Text,
} from '@chakra-ui/react';
import ButtonWrapper from '../../wrappers/ButtonWrapper';
import firebase from '../../utils/firebase';
import ClickableBadges from '../Propose/ClickableBadges';
import Badge from '../../../pages/Speakeasy/components/Badge';
import { useOption } from '../../context/OptionProvider';
import { useAuth } from '../../context/AuthProvider';

const { firestoreDB, FieldValue } = firebase;

const OpenOption = ({ onClose, handleDelete }) => {
  const { userId } = useAuth();
  const {
    question: initalQuestion,
    badge: initialBadge,
    optionId,
  } = useOption();
  const [question, setQuestion] = useState(initalQuestion);
  const [badge, setBadge] = useState(initialBadge);

  const toast = useToast();

  const handleCreate = useCallback(
    () =>
      firestoreDB
        .collection('options')
        .doc(optionId)
        .set({
          question,
          badge,
          userId,
          openedOn: FieldValue.serverTimestamp(),
          optionId,
        })
        .then(() =>
          toast({
            duration: 2000,
            title: 'Success.',
            description: 'option opened',
            status: 'success',
          }),
        )
        .then(handleDelete)
        .then(onClose)
        .catch(
          (err) =>
            toast({
              duration: 2000,
              title: 'An error occurred.',
              description: 'Something went wrong.',
              status: 'error',
            }) && console.log(err),
        ),
    [question, onClose, toast, userId, badge, handleDelete, optionId],
  );

  const handleSave = useCallback(
    () =>
      firestoreDB
        .collection('proposals')
        .doc(optionId)
        .set(
          {
            question,
            badge,
          },
          { merge: true },
        )
        .then(() =>
          toast({
            duration: 2000,
            title: 'Success.',
            description: 'proposal edited',
            status: 'success',
          }),
        )
        .then(onClose)
        .catch(
          (err) =>
            toast({
              duration: 2000,
              title: 'An error occurred.',
              description: 'Something went wrong.',
              status: 'error',
            }) && console.log(err),
        ),
    [question, onClose, toast, badge, optionId],
  );

  return (
    <VStack align="stretch" p={4} overflow="hidden" h="100%">
      <HStack h={8} justify="center">
        <Badge badge={badge} />
        <Text align="center" color="brand.purple">
          PROPOSAL
        </Text>
        <Badge badge={badge} />
      </HStack>
      <ClickableBadges setBadge={setBadge} badge={badge} />
      <Textarea
        rounded={0}
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      <ButtonWrapper
        alignSelf="flex-end"
        variant="outline"
        border="1px solid"
        borderColor='brand.orange'
        color='brand.orange'
        rounded={0}
        onClick={handleSave}
      >
        tap to save edits
      </ButtonWrapper>
      <Box flex={1} />
      <ButtonWrapper
        variant="outline"
        border="1px solid"
        borderColor='brand.orange'
        color='brand.orange'
        isFullWidth
        rounded={0}
        onClick={handleCreate}
      >
        tap to open option
      </ButtonWrapper>
    </VStack>
  );
};

export default OpenOption;
