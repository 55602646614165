import React, { useCallback, useState } from 'react';
import {
  HStack,
  VStack,
  StackDivider,
  Text,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import firebase from '../../utils/firebase';

const { firestoreDB, FieldValue } = firebase;

const User = ({
  user: { phoneNumber, balance, invites, userId },
}) => {
  const toast = useToast();
  const [loading, setLoading] = useState();

  const handleGrantInvite = useCallback(
    () =>
      firestoreDB
        .collection('users')
        .doc(userId)
        .set(
          {
            invites: FieldValue.increment(1),
          },
          { merge: true },
        )
        .then(() =>
          toast({
            duration: 2000,
            title: 'Success.',
            description: 'Invite granted',
            status: 'success',
          }),
        )
        .then(() => setLoading())
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast, userId],
  );

  // const handleGrantInvite = useCallback(
  //   () =>
  //     callFunction('grantInvite', { userId })
  //       .then(() =>
  //         toast({duration: 2000,
  //           title: 'Success.',
  //           description: 'Invite granted',
  //           status: 'success',
  //         }),
  //       )
  //       .then(() => setLoading())
  //       .catch((err) =>
  //         toast({duration: 2000,
  //           title: 'An error occurred.',
  //           description: 'Something went wrong.',
  //           status: 'error',
  //         }),
  //       ),
  //   [toast, userId],
  // );

  return (
    <VStack
      align="stretch"
      divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
      border="1px solid rgba(255,255,255,.16)"
      spacing={0}
    >
      <VStack spacing={1} flex={1} p={2} align="center">
        <Text fontSize="2xl" fontWeight="bold" lineHeight="1.5rem">
          {phoneNumber.slice(2) || '-'}
        </Text>
      </VStack>
      <HStack
        align="stretch"
        justify="center"
        spacing={0}
        divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
      >
        <VStack spacing={1} flex={1} p={2} align="center">
          {loading === 'revoke' ? (
            <Spinner />
          ) : (
            <>
              <MinusIcon color="brand.red" w={6} h={6} />
              <Text fontSize="xs" color='brand.orange'>
                tap to revoke
              </Text>
            </>
          )}
        </VStack>
        <VStack
          spacing={1}
          px={2}
          p={2}
          align="center"
          justify="center"
        >
          <Text fontSize="sm">INVITES</Text>
          <Text
            align="center"
            fontSize="2xl"
            fontWeight="bold"
            lineHeight="1.5rem"
          >
            {invites}
          </Text>
        </VStack>
        <VStack
          spacing={1}
          flex={1}
          p={2}
          align="center"
          onClick={() => {
            setLoading('grant');
            handleGrantInvite();
          }}
        >
          {loading === 'grant' ? (
            <Spinner />
          ) : (
            <>
              <AddIcon color="brand.green" w={6} h={6} />
              <Text fontSize="xs" color='brand.orange'>
                tap to grant
              </Text>
            </>
          )}
        </VStack>
      </HStack>
    </VStack>
  );
};

export default User;
