import React, { useCallback, useState } from 'react';
import {
  HStack,
  InputGroup,
  InputLeftAddon,
  Input,
  useToast,
} from '@chakra-ui/react';
import ButtonWrapper from '../../wrappers/ButtonWrapper';
import callFunction from '../../utils/callFunction';

const Invite = ({ isDisabled }) => {
  const [phone, setPhone] = useState('');
  const toast = useToast();

  const handleSubmit = useCallback(
    () =>
      callFunction('inviteUser', { phoneNumber: `+1${phone}` })
        .then((data) =>
          data.error
            ? toast({duration: 2000,
                title: 'An error occurred.',
                description: data.error,
                status: 'error',
              })
            : toast({duration: 2000,
                title: 'Success.',
                description: data,
                status: 'success',
              }),
        )
        .then(() => setPhone(''))
        .catch((err) =>
          toast({duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast, phone],
  );

  return (
    <HStack>
      <InputGroup>
        <InputLeftAddon children="+1" rounded={0} />
        <Input
          type="tel"
          placeholder="phone number"
          rounded={0}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          isInvalid={phone && phone.length < 10}
          errorBorderColor="red.300"
          disabled={isDisabled}
        />
      </InputGroup>
      <ButtonWrapper
        disabled={isDisabled || (phone && phone.length < 10)}
        onClick={handleSubmit}
        variant="outline"
        border="1px solid"
        borderColor='brand.orange'
        color='brand.orange'
        rounded={0}
      >
        invite
      </ButtonWrapper>
    </HStack>
  );
};

export default Invite;
