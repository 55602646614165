import React, { useState } from 'react';

const FiltersContext = React.createContext();
const FiltersDispatch = React.createContext();

const FiltersProvider = ({ children }) => {
  const [filters, setFilters] = useState([]);
  return (
    <FiltersDispatch.Provider value={setFilters}>
      <FiltersContext.Provider value={filters}>
        {children}
      </FiltersContext.Provider>
    </FiltersDispatch.Provider>
  );
};

const useFilters = () => {
  const context = React.useContext(FiltersContext);
  if (context === undefined) {
    throw new Error(
      'useFilters must be used within a FiltersProvider',
    );
  }
  return context;
};

const useFiltersDispatch = () => {
  const context = React.useContext(FiltersDispatch);
  if (context === undefined) {
    throw new Error(
      'useFiltersDispatch must be used within a FiltersProvider',
    );
  }
  return context;
};

export { FiltersProvider, useFilters, useFiltersDispatch };
