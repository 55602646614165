import React from 'react';
import {
  VStack,
  Heading,
  HStack,
  Icon,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { GiGlassShot } from 'react-icons/gi';
import Bounty from '../components/Bounty';
import { useAuth } from '../../../global/context/AuthProvider';

const Bounties = () => {
  const { shots } = useAuth();
  return (
    <VStack
      align="stretch"
      spacing={0}
      divider={<StackDivider borderColor="rgba(255,255,255,.16)" />}
    >
      <HStack
        justify="center"
        spacing={1}
        align="flex-end"
        px={8}
        py={4}
      >
        <Icon as={GiGlassShot} boxSize={12} color="brand.purple" />
        <Heading fontSize="5xl" lineHeight="3rem">
          {shots}
        </Heading>
      </HStack>
      <VStack align="stretch" bg="brand.background" p={4} spacing={4}>
        <Text color="brand.text">New</Text>
        {[
          {
            reward: 15,
            text: 'place an order',
            claimed: false,
            disabled: true,
          },
          {
            reward: 5,
            text: 'cancel an order',
            claimed: false,
            disabled: true,
          },
          {
            reward: 10,
            text: 'submit a proposal',
            claimed: false,
            disabled: true,
          },
          {
            reward: 25,
            text: 'enter a position',
            claimed: false,
            disabled: true,
          },
          {
            reward: 50,
            text: 'exit a position',
            claimed: false,
            disabled: true,
          },
        ].map((bounty, i) => (
          <Bounty key={i} {...bounty} />
        ))}
      </VStack>
      <VStack align="stretch" bg="brand.background" p={4} spacing={4}>
        <Text color="brand.text">Past</Text>
        {[
          { reward: 100, text: 'create an account', claimed: true },
        ].map((bounty, i) => (
          <Bounty key={i} {...bounty} />
        ))}
      </VStack>
    </VStack>
  );
};

export default Bounties;
