import React, { useCallback } from 'react';
import {
  HStack,
  Icon,
  VStack,
  useDisclosure,
  Box,
} from '@chakra-ui/react';
import { MdPlayArrow, MdPause, MdStop } from 'react-icons/md';
import DrawerWrapper from '../../../global/wrappers/DrawerWrapper';
import CloseOption from './CloseOption';
import { useOption } from '../../../global/context/OptionProvider';
import firebase from '../../../global/utils/firebase';

const { firestoreDB } = firebase;

const AdminControls = () => {
  const { optionId, isHalted } = useOption();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handlePause = useCallback(
    () =>
      !isHalted &&
      firestoreDB
        .collection('options')
        .doc(optionId)
        .set({ isHalted: true }, { merge: true }),
    [optionId, isHalted],
  );
  const handlePlay = useCallback(
    () =>
      isHalted &&
      firestoreDB
        .collection('options')
        .doc(optionId)
        .set({ isHalted: false }, { merge: true }),
    [optionId, isHalted],
  );

  return (
    <>
      <HStack
        spacing={0}
        border="1px solid"
        borderColor="brand.yellow"
        align="stretch"
        flex={1}
      >
        <Box
          py={1}
          px={3}
          onClick={(e) => {
            e.stopPropagation();
            return handlePause();
          }}
        >
          <Icon
            as={MdPause}
            boxSize={6}
            color={isHalted ? 'brand.red' : 'brand.yellow'}
          />
        </Box>
        <Box
          py={1}
          px={3}
          onClick={(e) => {
            e.stopPropagation();
            return handlePlay();
          }}
        >
          <Icon
            as={MdPlayArrow}
            boxSize={6}
            color={isHalted ? 'brand.yellow' : 'brand.green'}
          />
        </Box>
        <Box
          py={1}
          px={3}
          onClick={(e) => {
            e.stopPropagation();
            return onOpen();
          }}
        >
          <Icon as={MdStop} boxSize={6} color="brand.yellow" />
        </Box>
      </HStack>
      <DrawerWrapper
        isOpen={isOpen}
        onClose={onClose}
        header="Close Option"
      >
        <VStack align="stretch" p={4}>
          <CloseOption onClose={onClose} />
        </VStack>
      </DrawerWrapper>
    </>
  );
};

export default AdminControls;
