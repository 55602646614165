import React, { useEffect, useState } from 'react';
import {
  VStack,
  Heading,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import SignIn from '../modals/SignIn';

const Unauthenticated = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [phone, setPhone] = useState();

  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    if (params.has('phone')) {
      setPhone(params.get('phone'));
      window.history.replaceState({}, document.title, '/');
    }
  }, []);

  return (
    <VStack w="100%" align="center" h="100%">
      <VStack
        align="stretch"
        w="100%"
        px={4}
        spacing={4}
        overflow="hidden"
      >
        <Flex justify="space-between" align="center" pt={4}>
          <Heading size="md" fontWeight="thin" lineHeight={6}>
            tap to log in {`->`}
          </Heading>
          <Text onClick={onOpen} fontSize="2xl" lineHeight={6}>
            🤫
          </Text>
        </Flex>
      </VStack>
      <SignIn
        isOpen={isOpen || phone}
        onClose={onClose}
        phone={phone}
      />
    </VStack>
  );
};

export default Unauthenticated;
