import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';

const OptionsContext = React.createContext();

const { firestoreDB } = firebase;

const OptionsProvider = ({ children }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('options')
      .onSnapshot((snapshot) =>
        setOptions(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            optionId: doc.id,
          })),
        ),
      );
    return () => unsub();
  }, []);

  return (
    <OptionsContext.Provider value={options}>
      {children}
    </OptionsContext.Provider>
  );
};

const useOptions = (filters = []) => {
  const context = React.useContext(OptionsContext);
  if (context === undefined) {
    throw new Error(
      'useOptions must be used within a OptionsProvider',
    );
  }
  return context.flatMap((option) =>
    !filters.length || filters.find((key) => option.badge === key)
      ? [option]
      : [],
  );
};

export { OptionsProvider, useOptions };
