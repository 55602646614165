import React from 'react';
import { Icon } from '@chakra-ui/react';
import {
  GiBasketballBall,
  GiAmericanFootballHelmet,
  GiSoccerBall,
  GiGolfFlag,
  GiBaseballGlove,
  GiCardRandom,
  GiClapperboard,
  GiPodiumWinner,
} from 'react-icons/gi';

const mapping = {
  basketball: GiBasketballBall,
  football: GiAmericanFootballHelmet,
  soccer: GiSoccerBall,
  golf: GiGolfFlag,
  baseball: GiBaseballGlove,
  olympics: GiPodiumWinner,
  movies: GiClapperboard,
  miscellaneous: GiCardRandom,
  // moonshot: GiSpaceShuttle,
};

const Badge = ({ badge }) =>
  (badge && mapping[badge] && (
    <Icon as={mapping[badge]} boxSize={6} />
  )) || <Icon as={mapping.miscellaneous} boxSize={6} />;

export default Badge;
