import React, { useCallback } from 'react';
import {
  HStack,
  StackDivider,
  useDisclosure,
  useToast,
  Box,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon } from '@chakra-ui/icons';
import DrawerWrapper from '../../../global/wrappers/DrawerWrapper';
import OptionForm from '../../../global/drawers/OptionForm';
import { useOption } from '../../../global/context/OptionProvider';
import firebase from '../../../global/utils/firebase';

const { firestoreDB } = firebase;

const AdminProposalControls = () => {
  const { optionId } = useOption();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const handleDelete = useCallback(
    () =>
      firestoreDB
        .collection('proposals')
        .doc(optionId)
        .delete()
        .catch((err) =>
          toast({
            duration: 2000,
            title: 'An error occurred.',
            description: 'Something went wrong.',
            status: 'error',
          }),
        ),
    [toast, optionId],
  );

  return (
    <>
      <HStack
        spacing={0}
        divider={<StackDivider borderColor="brand.yellow" />}
        border="1px solid"
        borderColor="brand.yellow"
        align="stretch"
      >
        <Box
          py={1}
          px={4}
          onClick={(e) => {
            e.stopPropagation();
            return onOpen();
          }}
        >
          <EditIcon
            // boxSize={6}
            color="brand.yellow"
          />
        </Box>
        <Box
          py={1}
          px={4}
          onClick={(e) => {
            e.stopPropagation();
            return handleDelete();
          }}
        >
          <DeleteIcon
            // boxSize={6}
            color="brand.yellow"
          />
        </Box>
      </HStack>
      <DrawerWrapper
        onClose={onClose}
        isOpen={isOpen}
        header="Open Option"
      >
        <OptionForm onClose={onClose} handleDelete={handleDelete} />
      </DrawerWrapper>
    </>
  );
};

export default AdminProposalControls;
