import React, { useEffect, useState } from 'react';
import { VStack, Text } from '@chakra-ui/react';
import firebase from '../../../global/utils/firebase';
import AdminRequest from '../components/AdminRequest';

const { firestoreDB } = firebase;

const AdminNotifications = () => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const unsub = firestoreDB
      .collection('requests')
      .onSnapshot((snapshot) =>
        setRequests(
          snapshot.docs.map((doc) => ({
            ...doc.data(),
            requestId: doc.id,
          })),
        ),
      );
    return () => unsub();
  }, []);

  return (
    <VStack
      align="stretch"
      spacing={4}
      p={4}
      borderTop="1px solid"
      borderBottom="1px solid"
      borderColor="brand.yellow"
    >
      <Text color="brand.yellow">Requests</Text>
      {requests.length ? (
        requests.map((request) => (
          <AdminRequest key={request.requestId} request={request} />
        ))
      ) : (
        <Text color="brand.text">no requests at this time</Text>
      )}
    </VStack>
  );
};

export default AdminNotifications;
