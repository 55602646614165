import React from 'react';
import { OptionProvider } from '../../../global/context/OptionProvider';
import { useOptions } from '../../../global/context/OptionsProvider';
import { useFilters } from '../../../global/context/FiltersProvider';
import Card from './Card';
import { LikesProvider } from '../../../global/context/LikesProvider';
import { CommentsProvider } from '../../../global/context/CommentsProvider';
import OptionTile from '../components/OptionTile';

const Options = () =>
  useOptions(useFilters()).map((option) => (
    <OptionProvider option={option} key={option.optionId}>
      <CommentsProvider>
        <LikesProvider>
          <Card idPrefix={`option-${option.optionId}`}>
            <OptionTile />
          </Card>
        </LikesProvider>
      </CommentsProvider>
    </OptionProvider>
  ));

export default Options;
