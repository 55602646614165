import React from 'react';
import { HStack, Text, Icon } from '@chakra-ui/react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useLikes } from '../../../global/context/LikesProvider';

const Likes = () => {
  const { hasHearted, voteCount, handleSubmit } = useLikes();
  return (
    <HStack spacing={1} justify="flex-end" flex={1}>
      <Icon
        as={hasHearted ? AiFillHeart : AiOutlineHeart}
        // boxSize={4}
        color='brand.orange'
        onClick={(e) => {
          e.stopPropagation();
          return handleSubmit();
        }}
      />
      <Text color="brand.purple">x {voteCount}</Text>
    </HStack>
  );
};

export default Likes;
