import React from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

const DrawerWrapper = ({
  isOpen,
  onClose,
  header = 'Order Form',
  children,
}) => (
  <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
    <DrawerOverlay bg="rgba(0,0,0,.5)" />
    <DrawerContent bg="brand.card" h="100%">
      <DrawerCloseButton />
      <DrawerHeader>{header}</DrawerHeader>
      <DrawerBody p={0}>{children}</DrawerBody>
    </DrawerContent>
  </Drawer>
);

export default DrawerWrapper;
