import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  styles: {
    global: (props) => ({
      '.firebase-emulator-warning': {
        display: 'none',
      },
      html: {
        // h: '100%',
        // overflow: 'hidden',
      },
      body: {
        // overflow: 'hidden',
        bg: 'brand.background',
        // h: '100%',
      },
      '#root': {
        // h: '100%',
        // overflow: 'hidden',
      },
    }),
  },
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  components: {
    Alert: {},
    StatArrow: {},
  },
  fonts: {
    body: 'Rubik, sans-serif',
    heading: 'Roboto mono, monospace',
    mono: 'Rubik, monospace',
    // body: 'Roboto mono, monospace',
    // heading: 'Roboto mono, monospace',
    // mono: 'Roboto mono, monospace',
  },

  fontSize: 16,
  colors: {
    brand: {
      // background: '#121212',
      background: '#0F0F0F',
      foreground: '#121212',
      card: '#14171F',
      oldCard: '#171923',
      // orange: '#FA9573',
      orange: '#fa7042',
      yellow: '#fee05e',
      red: '#FF479A',
      // red: '#e31b61',
      // purple: '#bd8ad0',
      purple: '#C98BDF',
      green: '#36BF6A',
      // green: '#2aa258',
      form: '#242A38',
      border: 'rgba(255,255,255,.16)',
      text: '#A3A3A3',
      textOld: '#7A7A7A',
    },
  },
});

export default theme;
