import React from 'react';
import { Text, HStack } from '@chakra-ui/react';

const Summary = ({ price, side, quantity }) => (
  <HStack justify="space-between" align="flex-end">
    <HStack align="flex-end" fontSize="2xl" lineHeight={6}>
      <Text
        fontWeight="bold"
        color={side === 'buy' ? 'brand.green' : 'brand.red'}
      >
        {((quantity * price) / 100).toFixed(2) || '-'}
      </Text>
      <Text fontWeight="light">for</Text>
      <Text
        fontWeight="bold"
        color={side === 'sell' ? 'brand.green' : 'brand.red'}
      >
        {((quantity * 100 - quantity * price) / 100).toFixed(2) ||
          '-'}
      </Text>
    </HStack>
    <HStack spacing={1} lineHeight={4}>
      <Text>fee = </Text>
      <Text color="brand.purple" fontWeight="bold">
        {(quantity / 100).toFixed(2)}
      </Text>
    </HStack>
  </HStack>
);

export default Summary;
