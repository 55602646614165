import React from 'react';
import { OptionProvider } from '../../../global/context/OptionProvider';
import { useProposals } from '../../../global/context/ProposalsProvider';

import { useFilters } from '../../../global/context/FiltersProvider';
import Card from './Card';
import { LikesProvider } from '../../../global/context/LikesProvider';
import { CommentsProvider } from '../../../global/context/CommentsProvider';
import OptionTile from '../components/OptionTile';

const Proposals = () =>
  useProposals(useFilters()).map((proposal) => (
    <OptionProvider option={proposal} key={proposal.optionId}>
      <CommentsProvider>
        <LikesProvider>
          <Card idPrefix={`proposal-${proposal.optionId}`}>
            <OptionTile />
          </Card>
        </LikesProvider>
      </CommentsProvider>
    </OptionProvider>
  ));

export default Proposals;
